import { sizes } from "config";
import { UploadFormUtil } from "content";
import { AutoDiv, FlexColumn } from "components";
import { MuiFileInput } from "mui-file-input";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { useSelector } from "react-redux";
import { clientMountain } from "@ttp/goods-config-client";

const StyledPaper = styled(Paper)({
  width: sizes.uploadPreview,
  height: sizes.uploadPreview,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
});

export const ImageUpload = ({ uploadForm }: { uploadForm: UploadFormUtil }) => {
  const { updateFormData, updateFormState, formState } = uploadForm;
  const { uploadFile, step } = formState;

  useEffect(() => {
    if (!uploadFile) {
      updateFormData({ uploadFileBase64: "" });
      return;
    }

    try {
      const fileReader = new FileReader();

      fileReader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          const result = fileReader.result;
          updateFormData({ uploadFileBase64: result as string });
          updateFormState({ step: step + 1 });
        },
        false
      );

      if (uploadFile) {
        fileReader.readAsDataURL(uploadFile);
      }

      //URL.revokeObjectURL(src);
    } catch (e) {}
  }, [uploadFile]);

  return (
    <FlexColumn
      width={"100%"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      gap={20}
      paddingTop={20}
    >
      <StyledPaper elevation={6}>
        <img
          width={sizes.uploadPreview}
          alt={"Trash Panda Travels logo"}
          src={"assets/travels_fallback.webp"}
          style={{ objectFit: "contain" }}
        />
      </StyledPaper>

      <AutoDiv cursor={"pointer"}>
        <MuiFileInput
          id={"upload-field-to-focus"}
          style={{ width: sizes.uploadPreview }}
          label={"Upload your image."}
          value={uploadFile}
          onChange={(uploadFile) => updateFormState({ uploadFile: uploadFile })}
        />
      </AutoDiv>
    </FlexColumn>
  );
};
