import { GenericFunction } from "@ttp/common/types";

export enum HELLO_WORLD {
  HELLO = "HELLO",
  WORLD = "WORLD",
}

export type TESTTYPE = {
  foo: GenericFunction;
};
