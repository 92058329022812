import { EditFanUpdates, EditUserDocFields, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = EditUserDocFields &
  Pick<EditFanUpdates, "firstName" | "lastName" | "subscriptionStatus">;

type Params = any;

type Res = void;

export type PatchUserEndpoint = Endpoint<ReqBody, Params, Res>;

export const patchUserEndpoint = {
  protocol: PROTOCOL.PATCH,
  url: () => `/users/`,
};
