import { AdminDetail, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = {};

type Params = any;

type Res = AdminDetail[];

export type GetAdminsEndpoint = Endpoint<ReqBody, Params, Res>;

export const getAdminsEndpoint = {
  protocol: PROTOCOL.GET,
  url: () => `/admins`,
};
