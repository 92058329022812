import {
  Endpoint,
  PostTravelRequest,
  PostTravelResponse,
} from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = PostTravelRequest;

type Params = any;

type Res = PostTravelResponse;

export type PostTravelEndpoint = Endpoint<ReqBody, Params, Res>;

export const postTravelEndpoint = {
  protocol: PROTOCOL.POST,
  url: () => `/travels`,
};
