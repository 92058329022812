import { FlexRowProps } from "types";
import React from "react";

export const FlexRow: FlexRowProps = ({ children, onClick, ...props }) => {
  const style = {
    display: "flex",
    flexDirection: "row" as const,
    width: "100%",
    ...props,
  };

  return (
    <div style={style} onClick={onClick}>
      {children}
    </div>
  );
};
