import { AutoDivProps } from "types";
import React, { FC } from "react";

export const AutoDiv: FC<AutoDivProps> = ({
  children,
  onClick,
  className,
  ...styles
}) => {
  const style = {
    ...styles,
  };

  return (
    <div className={className} onClick={onClick} style={style}>
      {children}
    </div>
  );
};
