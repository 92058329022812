import { AutoDiv, FlexColumn, FlexRow } from "components";
import { UserInfoFormUtil } from "content";
import TextField from "@mui/material/TextField";
import { sizes } from "config";
import {
  Checkbox,
  FormGroup,
  FormHelperText,
  Link,
  Modal,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { SUBSCRIPTION_STATUS } from "@ttp/common";
import { useSelector } from "react-redux";
import { authMountain } from "@ttp/goods-config-client";
import React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";

export const AccountDetails = ({
  userInfoForm,
  onSave,
}: {
  userInfoForm: UserInfoFormUtil;
  onSave: () => any;
}) => {
  const { formData, updateFormData } = userInfoForm;
  const { firstName, lastName, subscriptionStatus, agreedToTOS } = formData;
  const { userDoc } = useSelector(authMountain.selectors.get);
  const [isTextModalOpen, setIsTextModalOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!onSave) return;

    onSave();
  };

  const displayName =
    firstName.value.length === 0
      ? ""
      : `${firstName.value[0].toUpperCase()}${firstName.value
          .slice(1)
          .toLowerCase()} ${
          lastName.value ? lastName.value[0].toUpperCase() : ""
        }.`;

  return (
    <AutoDiv marginTop={20}>
      <form onSubmit={handleSubmit}>
        <FlexColumn gap={20}>
          <TextField
            error={firstName.isError}
            helperText={firstName.validationText}
            label={"First Name"}
            onChange={({ target: { value } }) =>
              updateFormData({ firstName: value })
            }
            value={firstName.value}
          />
          <TextField
            error={lastName.isError}
            helperText={lastName.validationText}
            label={"Last Name"}
            onChange={({ target: { value } }) =>
              updateFormData({ lastName: value })
            }
            value={lastName.value}
          />
          <TextField
            label={"Display Name"}
            disabled
            value={displayName}
            helperText={
              "Your display name is generated from your first / last name. It will be shown alongside your contributions to the platform."
            }
          />
          <FormGroup>
            <AutoDiv
              display={"grid"}
              columnGap={10}
              gridTemplateColumns={`${48}px ${sizes.uploadPreview - 48}px`}
            >
              <Checkbox
                checked={
                  subscriptionStatus.value === SUBSCRIPTION_STATUS.CONSENTED
                }
                onClick={() => {
                  if (
                    subscriptionStatus.value === SUBSCRIPTION_STATUS.CONSENTED
                  ) {
                    updateFormData({
                      subscriptionStatus: SUBSCRIPTION_STATUS.REMOVED,
                    });
                    return;
                  }

                  updateFormData({
                    subscriptionStatus: SUBSCRIPTION_STATUS.CONSENTED,
                  });
                }}
              />
              <Typography style={{ fontSize: 12 }}>
                I consent to receive text messages from The Rocket City Trash
                Pandas.{" "}
                <Link onClick={() => setIsTextModalOpen(true)}>
                  What kind of texts will I get?
                </Link>
              </Typography>
              <Checkbox
                checked={agreedToTOS.value}
                disabled={userDoc?.agreedToTOS || undefined}
                title={"Please contact us to delete your account."}
                onClick={() =>
                  updateFormData({ agreedToTOS: !agreedToTOS.value })
                }
              />
              <Typography style={{ fontSize: 12 }}>
                I have read and agree to the{" "}
                <Link
                  href={"https://www.milb.com/about/privacy"}
                  referrerPolicy={"no-referrer"}
                  target={"blank"}
                >
                  Privacy Policy
                </Link>{" "}
                and{" "}
                <Link
                  href={"https://www.milb.com/about/terms-of-use"}
                  referrerPolicy={"no-referrer"}
                  target={"blank"}
                >
                  Terms of Service
                </Link>
                .
              </Typography>
            </AutoDiv>
            <FormHelperText>
              {subscriptionStatus.isError
                ? subscriptionStatus.validationText
                : agreedToTOS.isError
                ? agreedToTOS.validationText
                : ""}
            </FormHelperText>
          </FormGroup>
        </FlexColumn>
        <button hidden type={"submit"} />
      </form>
      <Modal open={isTextModalOpen}>
        <div style={{ height: "100%" }}>
          <FlexColumn justifyContent={"center"} alignItems={"center"}>
            <Card
              sx={{
                width: 350,
                height: 250,
                padding: "10px 20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant={"body1"}>
                The Trash Panda Travels promotion will send you text messages
                related to your uploads on the platform. For instance, you will
                be notified by text about your upload being approved. You may
                also receive promotional text messages from the Rocket City
                Trash Pandas.
              </Typography>
              <FlexRow justifyContent={"center"}>
                <Button
                  color={"primary"}
                  onClick={() => setIsTextModalOpen(false)}
                >
                  Ok
                </Button>
              </FlexRow>
            </Card>
          </FlexColumn>
        </div>
      </Modal>
    </AutoDiv>
  );
};
