import { CampaignDoc, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = {};

type Res =
  | (CampaignDoc & { segmentName: string; segmentLength: number })[]
  | void;

type ReqQuery = {
  status: CampaignDoc["status"];
};

export type GetCampaignsEndpoint = Endpoint<ReqBody, any, Res, ReqQuery>;

export const getCampaignsEndpoint = {
  protocol: PROTOCOL.GET,
  url: () => `/campaigns`,
};
