import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { AutoDiv, FlexColumn, FlexRow } from "components";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { sizes } from "config";
import { TRAVEL_CARD_VARIANTS } from "keys/TRAVEL_CARD_VARIANTS";
import { Image } from "antd";
import { useImageContext } from "context";
import {
  CircularProgress,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { TravelsDoc } from "@ttp/common";
import Paper from "@mui/material/Paper";

type TravelsCardProps = {
  variant?: TRAVEL_CARD_VARIANTS;
  location?: string;
  cta?: string;
  body?: string;
  isNoClose?: boolean;
  isNoElevation?: boolean;
  signature?: string;
  src?: string;
  onClick?: (...args: any) => any;
  onClickClose?: (...args: any) => any;
};

export const StyledCardMedium = styled(Card)({
  borderRadius: 8,
  minHeight: 100,
  minWidth: 350,
  backgroundColor: "white",
});

export const TravelsCard = ({
  variant = TRAVEL_CARD_VARIANTS.MEDIUM,
  location = "",
  body = "",
  signature = "",
  src = "",
  onClick,
  onClickClose,
  isNoClose,
  isNoElevation,
  cta,
}: TravelsCardProps) => {
  const { imagesByUrl, addImage } = useImageContext();

  const image = imagesByUrl[src] || "";

  useEffect(() => {
    if (image) {
      return;
    }

    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();

        reader.onload = function (e) {
          const base64String = e.target?.result || "";

          // Now you can use the base64String as needed, for example, set it as the source of an image element
          // Example: document.getElementById('myImage').src = base64String;
          addImage(src, base64String);
        };

        // Read the image blob as a Data URL (base64 encoding)
        reader.readAsDataURL(blob);
      })
      .catch((error) => console.error("Error fetching the image:", error));
  }, []);

  const CTA = (
    <Typography variant={"subtitle2"} color={"gray"} style={{ color: "gray" }}>
      {cta}
    </Typography>
  );

  const CloseButton = (
    <IconButton
      style={{
        color: "black",
        position: "absolute",
        top: 0,
        right: 0,
      }}
      onClick={onClickClose}
    >
      <Close fontSize={"small"} />
    </IconButton>
  );

  const ImageComponent = image ? (
    <Image
      alt={"Uploaded image"}
      height={sizes.travelCardImage[variant]}
      width={sizes.travelCardImage[variant]}
      style={{ borderRadius: 10, objectFit: "contain" }}
      src={image}
    />
  ) : (
    <AutoDiv height={sizes.travelCardImage[variant]}>
      <CircularProgress />
    </AutoDiv>
  );

  const Headline = (
    <Typography
      variant={"overline"}
      color={"black"}
      style={{ color: "black", transform: "translate(0px, -8px)" }}
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "1",
        WebkitBoxOrient: "vertical",
        maxWidth: "200px",
        wordWrap: "break-word",
      }}
    >
      {location}
    </Typography>
  );

  const Signature = (
    <Typography
      variant={"subtitle2"}
      color={"gray"}
      style={{ color: "gray", transform: "translate(0px, -8px)" }}
    >
      - {signature}
    </Typography>
  );

  const Body = (
    <Typography
      variant={"body2"}
      color={"black"}
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        maxWidth: "300px",
        color: "black",
        fontSize: 12,
        transform: "translate(0px, -12px)",
        wordWrap: "break-word",
      }}
    >
      {body}
    </Typography>
  );

  const containerProps = {
    elevation: isNoElevation ? undefined : 6,
    style: { cursor: onClick ? "pointer" : undefined },
    onClick: onClick,
  };

  if (variant === TRAVEL_CARD_VARIANTS.MEDIUM)
    return (
      <StyledCardMedium {...containerProps}>
        <FlexRow position={"relative"} padding={10} gap={10}>
          {!isNoClose && CloseButton}

          {cta && (
            <AutoDiv position={"absolute"} bottom={0} left={130}>
              {CTA}
            </AutoDiv>
          )}

          {ImageComponent}

          <FlexColumn>
            {Headline}
            {Body}
          </FlexColumn>
        </FlexRow>
      </StyledCardMedium>
    );

  if (variant === TRAVEL_CARD_VARIANTS.LARGE)
    return (
      <StyledCardMedium {...containerProps}>
        <FlexColumn position={"relative"} gap={10} alignItems={"center"}>
          <FlexRow
            justifyContent={"space-between"}
            paddingTop={10}
            paddingLeft={10}
            height={"30px"}
            minHeight={"30px"}
          >
            {Headline}
            {!isNoClose && CloseButton}
          </FlexRow>

          {ImageComponent}
          <FlexRow
            justifyContent={"flex-start"}
            padding={"5px 10px 0px 10px"}
            minHeight={40}
          >
            {Body}
          </FlexRow>

          {signature && (
            <FlexRow paddingRight={10} justifyContent={"flex-end"}>
              {Signature}
            </FlexRow>
          )}

          {cta && (
            <AutoDiv position={"absolute"} bottom={0} left={130}>
              {CTA}
            </AutoDiv>
          )}
        </FlexColumn>
      </StyledCardMedium>
    );

  return (
    <StyledCardMedium
      elevation={6}
      style={{ cursor: onClick ? "pointer" : undefined }}
      onClick={onClick}
    >
      <FlexRow position={"relative"} padding={10} gap={10}>
        {!isNoClose && CloseButton}

        {cta && (
          <AutoDiv position={"absolute"} bottom={0} left={130}>
            {CTA}
          </AutoDiv>
        )}

        {ImageComponent}

        <FlexColumn>
          {Headline}
          {Body}
        </FlexColumn>
      </FlexRow>
    </StyledCardMedium>
  );
};

export const ImageGridCard = ({
  content,
  src = "",
  travelId,
  isMobile,
}: { isMobile: boolean } & Pick<
  TravelsDoc,
  "src" | "travelId" | "content"
>) => {
  const { imagesByUrl, addImage } = useImageContext();

  const image = imagesByUrl[src] || "";

  const { signature, description, location } = content;

  useEffect(() => {
    if (image) {
      return;
    }

    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();

        reader.onload = function (e) {
          const base64String = e.target?.result || "";

          // Now you can use the base64String as needed, for example, set it as the source of an image element
          // Example: document.getElementById('myImage').src = base64String;
          addImage(src, base64String);
        };

        // Read the image blob as a Data URL (base64 encoding)
        reader.readAsDataURL(blob);
      })
      .catch((error) => console.error("Error fetching the image:", error));
  }, []);

  return (
    <ImageListItem
      key={travelId}
      sx={{
        width:
          sizes.travelCardImage[
            isMobile
              ? TRAVEL_CARD_VARIANTS.EXTRA_LARGE
              : TRAVEL_CARD_VARIANTS.MEDIUM
          ],
      }}
    >
      <Image
        alt={"Uploaded image"}
        width={
          sizes.travelCardImage[
            isMobile
              ? TRAVEL_CARD_VARIANTS.EXTRA_LARGE
              : TRAVEL_CARD_VARIANTS.MEDIUM
          ]
        }
        height={
          sizes.travelCardImage[
            isMobile
              ? TRAVEL_CARD_VARIANTS.EXTRA_LARGE
              : TRAVEL_CARD_VARIANTS.MEDIUM
          ]
        }
        style={{ borderRadius: 10, objectFit: "contain" }}
        src={image}
        preview={{
          toolbarRender: () => {
            const Headline = (
              <Typography
                variant={"overline"}
                color={"black"}
                style={{ color: "black", transform: "translate(0px, -8px)" }}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  maxWidth: "200px",
                  wordWrap: "break-word",
                }}
              >
                {location}
              </Typography>
            );

            const Signature = (
              <Typography
                variant={"subtitle2"}
                color={"gray"}
                style={{ color: "gray", transform: "translate(0px, -8px)" }}
              >
                - {signature}
              </Typography>
            );

            const Body = (
              <Typography
                variant={"body2"}
                color={"black"}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  maxWidth: "300px",
                  color: "black",
                  fontSize: 12,
                  transform: "translate(0px, -12px)",
                  wordWrap: "break-word",
                }}
              >
                {description}
              </Typography>
            );

            return (
              <Paper>
                <FlexColumn
                  minWidth={
                    sizes.travelCardImage[TRAVEL_CARD_VARIANTS.EXTRA_LARGE]
                  }
                  padding={25}
                >
                  {Body}
                  {Headline}
                  {Signature}
                </FlexColumn>
              </Paper>
            );
          },
        }}
      />
      <ImageListItemBar
        title={description}
        subtitle={`${location} - ${signature}`}
      />
    </ImageListItem>
  );
};
