import { useForm3 } from "utils/ui";
import { useEffect } from "react";
import { FormTypes } from "utils/ui/useForm2/useFormTypes";
import { travelMountain } from "@ttp/goods-config-client";
import { dependencies } from "content";
import { GenericAsync } from "@ttp/common";

const initialData = {
  uploadFileBase64: "",
  croppedUploadBase64: "",
  caption: "",
  location: "Toyota Field, AL",
  geoData: {
    lat: 34.6838,
    lng: -86.7253,
  },
};

const initialState = {
  uploadFile: null,
  step: 0,
  isSigningIn: false,
  isUploading: false,
  setLocationSearch: async () => {
    console.log("not implemented");
  },
};

type UploadFormState = {
  uploadFile: File | null;
  step: number;
  isSigningIn: boolean;

  isUploading: boolean;
  setLocationSearch: GenericAsync;
};

export type UploadFormUtil = FormTypes<
  typeof initialData,
  UploadFormState
>["Util"];

export const useUploadForm = () => {
  const uploadForm = useForm3<typeof initialData, UploadFormState>(
    initialData,
    initialState
  );
  const { setValidationConfig, resetForm } = uploadForm;

  useEffect(() => {
    setValidationConfig({
      location: (fieldValue, { newState }) => {
        if (newState.step < 3) return;

        if (!fieldValue)
          return { isError: true, validationText: "This field is required" };
        if (fieldValue.length > 40)
          return {
            isError: true,
            validationText:
              "This location is too long. You could try 'city, state'?",
          };
        if (fieldValue.length > 27) {
          return {
            isError: false,
            validationText: "This location might be cropped, as shown below.",
          };
        }
      },
    });
  }, []);

  const { getValidatedFormData } = uploadForm;

  const handleSave = () => {
    const validData = getValidatedFormData();

    if (!validData) return Promise.reject("INVALID_DATA");

    const {
      croppedUploadBase64: imageDataUrl,
      caption: description,
      location,
      geoData,
    } = validData;

    return travelMountain.operations.uploadNewTravel(dependencies)({
      imageDataUrl,
      description,
      location,
      geoData,
    });
  };

  const resetFormToInitialData = () => resetForm(initialData);

  return { ...uploadForm, handleSave, resetFormToInitialData };
};
