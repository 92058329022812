import {
  ClientDependencies,
  TravelMountainState,
} from "@ttp/goods-config-client/types";
import {
  DeleteTravelEndpoint,
  deleteTravelEndpoint,
  getAllTravelsEndpoint,
  GetAllTravelsEndpoint,
  getApprovedPromoEndpoint,
  GetApprovedPromoEndpoint,
  GetHiddenTravelsEndpoint,
  getHiddenTravelsEndpoint,
  getModerationListEndpoint,
  GetModerationListEndpoint,
  getTravelEndpoint,
  GetTravelEndpoint,
  patchTravelEndpoint,
  PatchTravelEndpoint,
  postApprovedPromoEndpoint,
  PostApprovedPromoEndpoint,
  SendApprovedPromoEndpoint,
  sendApprovedPromoEndpoint,
} from "@ttp/common";
import {
  entriesSelector,
  fieldSelector,
  getSelector,
  mountReducer,
  removeReducer,
  updateAction,
} from "@ttp/goods";
import {
  getMyTravels,
  getTravels,
  getTravelsByGeoHash,
  uploadNewTravel,
} from "@ttp/goods-config-client/utils/operations";
import { MOUNTAINS } from "@ttp/goods-config-client/keys";
import {
  createAxiosLaunchGetRequest as newGet,
  createAxiosLaunchRequest as newReq,
} from "@ttp/goods-config-client/utils/operationCreators";

// prettier-ignore
export const travelMountain = {
  actions: {
    mountReducer: (dependencies: ClientDependencies) => mountReducer(dependencies)<TravelMountainState>(MOUNTAINS.TRAVEL_MOUNTAIN, {geoHashMap:{}, hiddenTravels: [],  liveTravels: [], moderationTravels: [], publicTravelKeysMap:{}, publicTravels: []}),
    removeReducer: (dependencies: ClientDependencies) => removeReducer(dependencies)(MOUNTAINS.TRAVEL_MOUNTAIN),
    update: (dependencies: ClientDependencies) => updateAction(dependencies, MOUNTAINS.TRAVEL_MOUNTAIN),
  },
  operations: {
    deleteTravel: newReq<DeleteTravelEndpoint>(deleteTravelEndpoint),
    getApprovedPromo:newGet<GetApprovedPromoEndpoint>(getApprovedPromoEndpoint),
    getModerationList:newGet<GetModerationListEndpoint>(getModerationListEndpoint),
    getMyTravels,
    getTravel:newGet<GetTravelEndpoint>(getTravelEndpoint),
    getTravels,
    getTravelsAll:newGet<GetAllTravelsEndpoint>(getAllTravelsEndpoint),
    getTravelsByGeoHash,
    getTravelsHidden:newGet<GetHiddenTravelsEndpoint>(getHiddenTravelsEndpoint),
    patchTravel:newReq<PatchTravelEndpoint>(patchTravelEndpoint),
    postApprovedPromo:newReq<PostApprovedPromoEndpoint>(postApprovedPromoEndpoint),
    sendApprovedPromo:newReq<SendApprovedPromoEndpoint>(sendApprovedPromoEndpoint),
    uploadNewTravel
  },
  selectors:{
    entries: entriesSelector<TravelMountainState>(MOUNTAINS.TRAVEL_MOUNTAIN),
    get: getSelector<TravelMountainState>(MOUNTAINS.TRAVEL_MOUNTAIN),
    travelField: (field) => fieldSelector<TravelMountainState>(MOUNTAINS.TRAVEL_MOUNTAIN, field),
  },
};
