import {
  AXIOS_LAUNCH_ROUTES,
  axiosLaunchRoutes,
  GetModerationListResponse,
} from "@ttp/common";
import { ClientDependencies } from "@ttp/goods-config-client/types";

export const getTravelsToModerate = (
  dependencies: Pick<ClientDependencies, "axiosLaunch">
) => {
  const { axiosLaunch } = dependencies;

  return async () => {
    try {
      const response = await axiosLaunch.get<GetModerationListResponse>(
        axiosLaunchRoutes[AXIOS_LAUNCH_ROUTES.TRAVELS_GET_MODERATION_LIST].url()
      );

      if (!response.data) {
        return new Error("Unable to get moderation list.");
      }

      return response.data as GetModerationListResponse;
    } catch (e) {
      return new Error("Something unexpected happened in getTravels");
    }
  };
};
