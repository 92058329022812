import { StyledFirebaseAuthCompat } from "components";
import React from "react";

import * as firebaseui from "firebaseui";
import { PhoneAuthProvider } from "firebase/auth";

export const useFirebaseUI = (firebaseAuth: any) => {
  const uiConfig = {
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => {
        return false;
      },
    },

    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: PhoneAuthProvider.PROVIDER_ID,
      },
    ],
  } as firebaseui.auth.Config;

  const result = {} as { Component?: any };

  result.Component = () => {
    return (
      <StyledFirebaseAuthCompat
        firebaseAuth={firebaseAuth}
        uiConfig={uiConfig}
      />
    );
  };

  return result;
};
