import { Endpoint, GetHiddenTravelsResponse } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = any;

type Params = any;

type Res = GetHiddenTravelsResponse;

export type GetHiddenTravelsEndpoint = Endpoint<ReqBody, Params, Res>;

export const getHiddenTravelsEndpoint = {
  protocol: PROTOCOL.GET,
  url: () => `/travels/hidden`,
};
