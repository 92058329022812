import { ClientDependencies } from "@ttp/goods-config-client";

import * as auth from "firebase/auth";
import * as database from "firebase/database";
import * as firestore from "firebase/firestore";
import * as storage from "firebase/storage";

import { axiosLaunch, firebaseRocket, reduxToyota } from "loaders";

import * as xlsxTricky from "xlsx";

import * as reduxToolkit from "@reduxjs/toolkit";

const {
  app,
  auth: rocketAuth,
  firestore: rocketFirestore,
  database: rocketDatabase,
  storage: rocketStorage,
} = firebaseRocket;

const { store, reducers } = reduxToyota;
export const dependencies: ClientDependencies = {
  Error: Error,
  app,
  auth,
  axios: axiosLaunch, // cut this out if we're using it anywhere
  axiosLaunch,
  database: { ...database, Database: rocketDatabase },
  firestore: { ...firestore, Firestore: rocketFirestore },
  logger: {
    error: console.error,
    log: console.log,
    warn: console.warn,
  },
  reducers,
  reduxToolkit,
  rocketAuth,
  rocketStorage,
  storage: { ...storage },
  store,
  xlsxTricky,
};
