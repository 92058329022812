import { ANALYTIC_USER_EVENTS, PROTOCOL } from "@ttp/common/keys";
import { Endpoint, UserEventDoc, UserEventFields } from "@ttp/common/types";
type ReqBody = {
  event: ANALYTIC_USER_EVENTS;
  eventFields: UserEventFields;
};

type Res = UserEventDoc[] | void;

export type PostUserAnalyticsEndpoint = Endpoint<ReqBody, any, Res>;

export const postUserAnalyticsEndpoint = {
  protocol: PROTOCOL.POST,
  url: () => `/analytics/userEvents`,
};
