import * as THREE from "three";
import React, { useEffect, useRef } from "react";

export const HomeGlobe = ({
  onGlobeLoaded,
}: {
  onGlobeLoaded: (...args: any) => any;
}) => {
  const outputRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const globeRef = useRef<any>(null);

  const loadGlobe = async () => {
    const GlobeGl = await import("globe.gl");
    const Globe = GlobeGl.default;

    if (outputRef.current) {
      globeRef.current = Globe({ animateIn: false })
        .onGlobeReady(() => onGlobeLoaded && onGlobeLoaded())
        .globeImageUrl(
          "//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
        )
        .bumpImageUrl("//unpkg.com/three-globe/example/img/earth-topology.png")
        .backgroundImageUrl(
          "//unpkg.com/three-globe/example/img/night-sky.png"
        )(outputRef.current);
    }

    if (globeRef.current) {
      // custom globe material
      const globeMaterial = globeRef.current.globeMaterial();
      // @ts-ignore
      globeMaterial.bumpScale = 10;
      new THREE.TextureLoader().load(
        "//unpkg.com/three-globe/example/img/earth-water.png",
        (texture) => {
          // @ts-ignore
          globeMaterial.specularMap = texture;
          // @ts-ignore
          globeMaterial.specular = new THREE.Color("grey");
          // @ts-ignore
          globeMaterial.shininess = 0;
        }
      );

      if (globeRef.current && containerRef.current) {
        globeRef.current.height(containerRef.current.clientHeight);
        globeRef.current.width(containerRef.current.clientWidth);
      }

      globeRef.current.pointOfView({
        altitude: 3,
        lat: 34.7304,
        lng: -86.5861,
      });
    }

    setTimeout(() => {
      // wait for scene to be populated (asynchronously)

      if (globeRef.current) {
        const directionalLight = globeRef.current
          .scene()
          .children.find((obj3d: any) => obj3d.type === "DirectionalLight");
        directionalLight && directionalLight.position.set(1, 1, 1); // change light position to see the specularMap's effect
      }
    }, 200);
  };

  useEffect(() => {
    if (globeRef.current && containerRef.current) {
      globeRef.current.pointOfView(
        {
          altitude: 3,
          lat: 34.7304,
          lng: -86.5861,
        },
        1000
      );
    }
  }, []);

  useEffect(() => {
    if (!window) return;

    loadGlobe();
    const callback = () => {
      if (globeRef.current && containerRef.current) {
        globeRef.current.height(containerRef.current.clientHeight);
        globeRef.current.width(containerRef.current.clientWidth);
      }
    };
    window.addEventListener("resize", callback);

    return () => window.removeEventListener("resize", callback);
  }, [window]);

  return (
    <div
      ref={containerRef}
      style={{
        height: "100%",
        position: "relative",
        width: "100%",
      }}
    >
      <div ref={outputRef} style={{ height: "100%", width: "100%" }}></div>
    </div>
  );
};
