import { AxiosRequestConfig } from "axios";
import { ClientDependencies } from "@ttp/goods-config-client/types";

/**
 * Intended as middleware for axios instance.
 * Takes in an axios config and returns a config
 * with authorization header
 */
export const addFirebaseAuthToken = (
  dependencies: Pick<ClientDependencies, "app" | "auth">
) => {
  const { auth, app } = dependencies;

  const firebaseAuth = auth.getAuth(app);

  return async (config: AxiosRequestConfig) => {
    const token = await firebaseAuth.currentUser?.getIdToken(true);
    const headers = config?.headers;

    if (headers && token) headers.authorization = `${token}`;

    return config;
  };
};
