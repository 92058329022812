import { createGoodsReducer, injectReducer } from "@ttp/goods/utils/store";
import { Dependencies, ReduxStoreIntegration } from "@ttp/goods";

export const mountReducer = (
  dependencies: Dependencies & ReduxStoreIntegration
) => {
  return <State = any>(path, initialState?: State) => {
    const reducer = createGoodsReducer(dependencies)<State>(path, initialState);

    if (reducer instanceof Error) {
      throw reducer;
    }

    injectReducer(dependencies)(path, reducer);
  };
};
