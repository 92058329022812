import { AuthClaims, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = AuthClaims;

type Params = { adminId: string };

type Res = void;

export type PatchAdminEndpoint = Endpoint<ReqBody, Params, Res>;

export const patchAdminEndpoint = {
  protocol: PROTOCOL.PATCH,
  url: ({ adminId }: Params) => `/admin/${adminId}`,
};
