import {
  AccountDetailsPage,
  ExplorePage,
  SignInPage,
  UploadPage,
  AboutPage,
} from "pages";
import { ResponsiveMUILayout } from "layouts";
import { createBrowserRouter } from "react-router-dom";
import { ROUTE_KEYS } from "keys";
import { RouteType } from "types";

export const router = () => {
  const routes = [
    {
      element: (
        <ResponsiveMUILayout>
          <ExplorePage />
        </ResponsiveMUILayout>
      ),
      path: ROUTE_KEYS.ROOT,
    },
    {
      element: (
        <ResponsiveMUILayout>
          <UploadPage />
        </ResponsiveMUILayout>
      ),
      path: ROUTE_KEYS.UPLOAD,
    },
    {
      element: (
        <ResponsiveMUILayout>
          <SignInPage />
        </ResponsiveMUILayout>
      ),
      path: ROUTE_KEYS.SIGN_IN,
    },
    {
      element: (
        <ResponsiveMUILayout>
          <AccountDetailsPage />
        </ResponsiveMUILayout>
      ),
      path: ROUTE_KEYS.ACCOUNT_DETAILS,
    },
    {
      element: (
        <ResponsiveMUILayout>
          <AboutPage />
        </ResponsiveMUILayout>
      ),
      path: ROUTE_KEYS.ABOUT,
    },
  ] as RouteType[];

  return createBrowserRouter(routes);
};
