export * from "./ANALYTIC_USER_EVENTS";
export * from "./AUTH_CLAIMS";
export * from "./AXIOS_LAUNCH_ROUTES";
export * from "./ERROR_CODES";
export * from "./HELLO_WORLD";
export * from "./HTTP";
export * from "./PATH";
export * from "./STATUS";

export const foo = {
  itWorks: true,
};
