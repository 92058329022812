import { EditCampaignUpdates, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = EditCampaignUpdates;

type Params = { campaignId: string };

type Res = void;

export type PatchCampaignEndpoint = Endpoint<ReqBody, Params, Res>;

export const patchCampaignEndpoint = {
  protocol: PROTOCOL.PATCH,
  url: ({ campaignId }: Params) => `/campaigns/${campaignId}`,
};
