import {
  ClientDependencies,
  UploadNewTravelParams,
} from "@ttp/goods-config-client/types";
import { createAxiosLaunchRequest as newReq } from "@ttp/goods-config-client/utils/operationCreators";
import { postTravelEndpoint, PostTravelEndpoint } from "@ttp/common";

export const uploadNewTravel = (
  dependencies: Pick<
    ClientDependencies,
    | "storage"
    | "Error"
    | "axiosLaunch"
    | "rocketStorage"
    | "rocketAuth"
    | "logger"
  >
) => {
  const {
    Error,
    storage: { ref, uploadString },
    rocketAuth: { currentUser },
    rocketStorage,
  } = dependencies;

  return async ({
    imageDataUrl,
    description,
    geoData,
    location,
  }: UploadNewTravelParams) => {
    try {
      const uploadUtil = newReq<PostTravelEndpoint>(postTravelEndpoint);

      const axiosResponse = await uploadUtil(dependencies)(
        {},
        { description, geoData, location }
      );

      if (!axiosResponse.data) {
        return new Error("Failed to create upload intent");
      }

      const { travelId } = axiosResponse.data;

      const travelRef = ref(
        rocketStorage,
        `travels/${currentUser.uid}/${travelId}.png`
      );

      await uploadString(travelRef, imageDataUrl, "data_url", {
        cacheControl: "public, max-age=604800000",
      });

      return;
    } catch (e) {
      return new Error("Something unexpected happened in getTravels");
    }
  };
};
