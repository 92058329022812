import * as geofire from "geofire-common";
import {
  ClientDependencies,
  TravelMountainState,
} from "@ttp/goods-config-client/types";
import { getSelector, updateAction } from "@ttp/goods";
import { MOUNTAINS } from "@ttp/goods-config-client";
import { TRAVEL_STATUS } from "@ttp/common";
export const getTravelsByGeoHash = (dependencies: ClientDependencies) => {
  const {
    firestore: {
      collection,
      query,
      where,
      startAt,
      endAt,
      orderBy,
      Firestore,
      getDocs,
    },
    store,
    logger,
  } = dependencies;

  return async ({
    lng,
    lat,
    radiusInM = 50 * 1000,
  }: {
    lat: number;
    lng: number;
    radiusInM: number;
  }) => {
    const get = getSelector<TravelMountainState>(MOUNTAINS.TRAVEL_MOUNTAIN),
      update = updateAction<Partial<TravelMountainState>>(
        dependencies,
        MOUNTAINS.TRAVEL_MOUNTAIN
      );
    try {
      // update this to make use of the geohash query
      // leverage local state to make caching happen here.
      // I want to call this anytime I enter a new geohash, and have it only get more if we have more to get
      // order by created, only return most recent 25,
      // store in state the geohashes we've queried, whether there's more to get in them
      // store in state the most recent geohash we searched, only search if it changes
      //

      const currentGeoHash = geofire.geohashForLocation([lat, lng], 3);

      const {
        geoHashMap = {},
        lastFetched,
        latestGeoHash,
      } = get(store.getState());

      if (latestGeoHash === currentGeoHash) return;
      if (geoHashMap[currentGeoHash]) return;
      if (lastFetched && Date.now() - lastFetched < 5000) return;

      // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
      // a separate query for each pair. There can be up to 9 pairs of bounds
      // depending on overlap, but in most cases there are 4.
      const bounds = geofire.geohashQueryBounds([lat, lng], radiusInM);
      const hashMapUpdates = {};
      const promises = [];
      for (let i = 0, n = bounds.length; i < n; i++) {
        const thisBounds = bounds[i];

        const keyArray = [...thisBounds];
        keyArray.sort((a, b) => (a > b ? 1 : 0));
        const key = `${keyArray[0]}^${keyArray[1]}`;

        if (geoHashMap[key] || hashMapUpdates[key]) {
          continue;
        }

        hashMapUpdates[key] = true;
        const travelsRef = collection(Firestore, "travels");
        const travelsQuery = query(
          travelsRef,
          orderBy("geoHash"),
          where("status", "==", TRAVEL_STATUS.PUBLIC),
          startAt(thisBounds[0]),
          endAt(thisBounds[1])
        );

        promises.push(getDocs(travelsQuery));
      }

      // Collect all the query results together into a single list
      Promise.all(promises)
        .then((snapshots) => {
          const matchingDocs = [];

          for (const snap of snapshots) {
            for (const doc of snap.docs) {
              const {
                content: {
                  geoData: { lat: latFromDoc, lng: lngFromDoc },
                },
              } = doc.data();

              // We have to filter out a few false positives due to GeoHash
              // accuracy, but most will match
              const distanceInKm = geofire.distanceBetween(
                [latFromDoc, lngFromDoc],
                [lat, lng]
              );
              const distanceInM = distanceInKm * 1000;
              if (distanceInM <= radiusInM) {
                matchingDocs.push(doc.data());
              }
            }
          }
          // filter out travels we've already added to state.
          const { publicTravelKeysMap } = get(store.getState());

          const filteredDocs = matchingDocs.filter(({ travelId }) => {
            return publicTravelKeysMap[travelId] === undefined;
          });

          return filteredDocs;
        })
        .then((matchingDocs = []) => {
          // Process the matching documents
          // ...

          const { publicTravels, publicTravelKeysMap } = get(store.getState());
          const matchingKeys = matchingDocs.reduce((a, { travelId }) => {
            return { ...a, [travelId]: true };
          }, {});

          update({
            geoHashMap: { ...geoHashMap, ...hashMapUpdates },
            latestGeoHash: currentGeoHash,
            publicTravelKeysMap: { ...publicTravelKeysMap, ...matchingKeys },
            publicTravels: [...publicTravels, ...matchingDocs],
          });
        });
    } catch (e) {
      logger.error(e);
      return new Error("Something unexpected happened in getTravelsByGeoHash");
    }
  };
};
