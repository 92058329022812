import {
  Dependencies,
  FirebaseAdminFirestoreIntegration,
} from "@ttp/goods/types";

const filename = "adminAdd";

export const adminAdd = async (
  dependencies: Dependencies & FirebaseAdminFirestoreIntegration,
  path: string,
  updateObject: any
) => {
  const { logger, Error, firestore } = dependencies;

  if (!logger || !Error || !firestore)
    throw "Missing dependencies in ${filename}";

  try {
    // Business Logic ###########################################

    await firestore.collection(path).doc().set(updateObject);

    //
  } catch (error) {
    const message = `Unexpected error in ${filename}`;
    logger.error(message, error);
    return new Error(message);
  }
};
