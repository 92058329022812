import { CampaignDoc, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = {};

type Params = { campaignId: string };

type Res =
  | (CampaignDoc & { segmentName: string; segmentLength: number })
  | void;

export type GetCampaignEndpoint = Endpoint<ReqBody, Params, Res>;

export const getCampaignEndpoint = {
  protocol: PROTOCOL.GET,
  url: ({ campaignId }: Params) => `/campaigns/${campaignId}`,
};
