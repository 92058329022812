import {
  DocWithCreated as docTyper,
  FirestoreDocWithCreated as firestoreTyper,
} from "@ttp/common/types/firestore";
import { FanDoc, SegmentParticipantDoc } from "@ttp/common/types";
import { SEGMENT_STATUS } from "@ttp/common/keys";

export type EditSegmentUpdates = Partial<
  Omit<SegmentDoc, typeof restrictedSegmentFields[number]> & {
    addRows: (Pick<SegmentParticipantDoc, "customParams"> &
      Pick<FanDoc, "firstName" | "lastName" | "phone"> &
      Partial<Pick<FanDoc, "fanId">>)[];
    removeRows: string[];
  }
>;

export type SegmentDoc = docTyper<{
  name: string;
  status: SEGMENT_STATUS;
  customParams: Record<string, any>;
  length?: number;

  segmentId: string;
}>;

export type SegmentParticipationFirestoreDoc = firestoreTyper<SegmentDoc>;

export const restrictedSegmentFields = [
  "segmentId",
  "createdAt",
  "length",
] as const;
