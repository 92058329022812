import {
  Endpoint,
  PatchTravelRequest,
  PatchTravelResponse,
} from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = PatchTravelRequest;

type Params = { travelId: string };

type Res = PatchTravelResponse;

export type PatchTravelEndpoint = Endpoint<ReqBody, Params, Res>;

export const patchTravelEndpoint = {
  protocol: PROTOCOL.PATCH,
  url: ({ travelId }: Params) => `/travels/${travelId}`,
};
