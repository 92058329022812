import { UploadFormUtil } from "content";
import {
  AutoDiv,
  FlexColumn,
  ReactGoogleMapsWithoutZone,
  TravelsCard,
} from "components";
import "react-image-crop/dist/ReactCrop.css";
import { TRAVEL_CARD_VARIANTS } from "keys/TRAVEL_CARD_VARIANTS";
import React, { useEffect, useRef, useState } from "react";
import { SESSION_STORAGE_KEYS } from "keys";
import { GoogleMapOptions } from "types";
import { useSelector } from "react-redux";
import { clientMountain } from "@ttp/goods-config-client";

export const ConfirmUpload = ({
  uploadForm,
}: {
  uploadForm: UploadFormUtil;
}) => {
  const { formData } = uploadForm;
  const { croppedUploadBase64, caption, location, geoData } = formData;
  const markerRef = useRef<null | google.maps.Marker>(null);

  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService>();
  const [mapInstance, setMapInstance] = useState<google.maps.Map>();

  const handleApiLoaded = (map: google.maps.Map) => {
    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.HAS_GOOGLE_MAPS_LOADED_ONCE,
      "true"
    );

    setPlacesService(new google.maps.places.PlacesService(map));
    setMapInstance(map);
  };

  const style = {
    height: "100%",
    width: "100%",
  } as React.CSSProperties;

  const options = {
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 4,
  } as GoogleMapOptions;

  useEffect(() => {
    if (!geoData.value || !mapInstance) {
      if (markerRef.current) markerRef.current.setMap(null);
      markerRef.current = null;
      return;
    }

    if (markerRef.current) markerRef.current.setMap(null);

    // Origins, anchor positions and coordinates of the marker increase in the X
    // direction to the right and in the Y direction down.
    const image = {
      // The anchor for this image is the base of the flagpole at (x, y).
      anchor: new google.maps.Point(31, 102),

      // The origin for this image is (0, 0).
      origin: new google.maps.Point(0, 0),

      // This marker is x pixels wide by y pixels high.
      size: new google.maps.Size(59, 120),

      url: require("../../../content/images/map-pin.png"),
    };

    markerRef.current = new google.maps.Marker({
      map: mapInstance,
      position: geoData.value,
      title: "Your pin - other fans will see this on the map!",
      icon: image,
    });

    mapInstance.setCenter(geoData.value);

    return () => {
      if (markerRef.current) markerRef.current.setMap(null);
      markerRef.current = null;
    };
  }, [geoData.value, mapInstance]);

  const { isMobile } = useSelector(clientMountain.selectors.get);
  return (
    <FlexColumn
      width={"100%"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      gap={20}
      paddingTop={20}
    >
      <AutoDiv
        height={"100vh"}
        position={"absolute"}
        top={0}
        left={0}
        width={"100vw"}
        zIndex={0}
      >
        <ReactGoogleMapsWithoutZone
          style={style}
          onApiLoaded={handleApiLoaded}
          center={geoData.value || undefined}
          options={options}
        />
      </AutoDiv>

      <FlexColumn
        justifyContent={"flex-end"}
        paddingBottom={80}
        height={300}
        position={"fixed"}
        bottom={0}
        zIndex={10000}
        pointerEvents={"none"}
      >
        <AutoDiv pointerEvents={"all"}>
          <TravelsCard
            src={croppedUploadBase64.value}
            body={caption.value}
            location={location.value}
            variant={TRAVEL_CARD_VARIANTS.MEDIUM}
            signature={""}
            isNoClose
          />
        </AutoDiv>
      </FlexColumn>
    </FlexColumn>
  );
};
