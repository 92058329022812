import {
  getCampaignEndpoint,
  GetCampaignEndpoint,
  getCampaignsEndpoint,
  GetCampaignsEndpoint,
  getSentCampaignEndpoint,
  GetSentCampaignEndpoint,
  patchCampaignEndpoint,
  PatchCampaignEndpoint,
  postCampaignEndpoint,
  PostCampaignEndpoint,
  sendCampaignEndpoint,
  SendCampaignEndpoint,
} from "@ttp/common";
import {
  createAxiosLaunchGetRequest as newGet,
  createAxiosLaunchRequest as newReq,
} from "@ttp/goods-config-client/utils/operationCreators";

export const campaignMountain = {
  operations: {
    getCampaign: newGet<GetCampaignEndpoint>(getCampaignEndpoint),
    getCampaigns: newGet<GetCampaignsEndpoint>(getCampaignsEndpoint),
    getSentCampaign: newGet<GetSentCampaignEndpoint>(getSentCampaignEndpoint),
    patchCampaign: newReq<PatchCampaignEndpoint>(patchCampaignEndpoint),
    postCampaign: newReq<PostCampaignEndpoint>(postCampaignEndpoint),
    sendCampaign: newReq<SendCampaignEndpoint>(sendCampaignEndpoint),
  },
};
