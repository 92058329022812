import { Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = { fromPhone: string; bodyText: string };

type Params = any;

type Res = void;

export type PostApprovedPromoEndpoint = Endpoint<ReqBody, Params, Res>;

export const postApprovedPromoEndpoint = {
  protocol: PROTOCOL.POST,
  url: () => `/approved-promo-config`,
};
