import { UserDoc as DocType, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = {};

type Res = DocType[] | void;

type ReqQuery = {
  startAt: string;
  endBefore: string;
};

export type GetUsersEndpoint = Endpoint<ReqBody, any, Res, ReqQuery>;

export const getUsersEndpoint = {
  protocol: PROTOCOL.GET,
  url: () => `/users`,
};
