import { addFirebaseAuthToken } from "@ttp/goods-config-client/utils/operations/addFirebaseAuthToken";
import { AuthMountainState } from "@ttp/goods-config-client/types/state/authMountain";
import { ClientDependencies } from "@ttp/goods-config-client/types";
import { connectClientAuthInstance } from "@ttp/goods-config-client/utils/operations";
import {
  ConnectUserEndpoint,
  connectUserEndpoint,
  patchUserEndpoint,
  PatchUserEndpoint,
} from "@ttp/common";
import {
  entriesSelector,
  fieldSelector,
  getSelector,
  mountReducer,
  removeReducer,
  updateAction,
} from "@ttp/goods";
import { MOUNTAINS } from "@ttp/goods-config-client/keys";
import { createAxiosLaunchRequest as newReq } from "@ttp/goods-config-client/utils/operationCreators";

export const authMountain = {
  actions: {
    mountReducer: (dependencies: ClientDependencies) =>
      mountReducer(dependencies)<AuthMountainState>(MOUNTAINS.AUTH_MOUNTAIN, {
        user: {},
      }),
    removeReducer: (dependencies: ClientDependencies) =>
      removeReducer(dependencies)(MOUNTAINS.AUTH_MOUNTAIN),
    update: (dependencies: ClientDependencies) =>
      updateAction<AuthMountainState>(dependencies, MOUNTAINS.AUTH_MOUNTAIN),
  },
  operations: {
    addFirebaseAuthToken,
    connectClientAuthInstance,
    connectUser: newReq<ConnectUserEndpoint>(connectUserEndpoint),
    patchUser: newReq<PatchUserEndpoint>(patchUserEndpoint),
  },
  selectors: {
    entries: entriesSelector<AuthMountainState>(MOUNTAINS.AUTH_MOUNTAIN),
    field: (field: keyof AuthMountainState) =>
      fieldSelector<AuthMountainState>(MOUNTAINS.AUTH_MOUNTAIN, field),
    get: getSelector<AuthMountainState>(MOUNTAINS.AUTH_MOUNTAIN),
  },
};
