export enum AXIOS_LAUNCH_ROUTES {
  TRAVELS_PATCH = "TRAVELS_PATCH",
  TRAVELS_UPLOAD = "TRAVELS_UPLOAD",
  TRAVELS_GET_MODERATION_LIST = "TRAVELS_GET_MODERATION_LIST",

  ANALYTICS_POST_USER_EVENT = "ANALYTICS_POST_USER_EVENT",
  ANALYTICS_GET_USER_EVENTS = "ANALYTICS_GET_USER_EVENTS",

  FANS_POST = "FANS_POST",
  FANS_LIST = "FANS_LIST",
  EDIT_FAN = "EDIT_FAN",

  USERS_CONNECT = "USERS_CONNECT",
  LIST_USERS = "LIST_USERS",

  SEGMENT_POST = "SEGMENT_POST",
  SEGMENT_EDIT = "SEGMENT_EDIT",
  SEGMENT_GET = "SEGMENT_GET",
  SEGMENTS = "SEGMENTS",

  CAMPAIGN_POST = "CAMPAIGN_POST",
  CAMPAIGN_EDIT = "CAMPAIGN_EDIT",
  CAMPAIGN_GET = "CAMPAIGN_GET",
  CAMPAIGNS = "CAMPAIGNS",
  CAMPAIGN_SEND = "CAMPAIGN_SEND",
}
