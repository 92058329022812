// prettier-ignore
export const env = {

    ACCESS_EMAIL: process.env.ACCESS_EMAIL,

    NODE_ENV: process.env.NODE_ENV,

    // axios Launch
    REACT_APP_AXIOS_LAUNCH_URL: process.env.REACT_APP_AXIOS_LAUNCH_URL,

    // firebase rocket emulator
    REACT_APP_FIREBASE_ROCKET_AUTH_EMULATOR_HOST: process.env.REACT_APP_FIREBASE_ROCKET_AUTH_EMULATOR_HOST,
    REACT_APP_FIREBASE_ROCKET_DATABASE_EMULATOR_HOST: process.env.REACT_APP_FIREBASE_ROCKET_DATABASE_EMULATOR_HOST,
    REACT_APP_FIREBASE_ROCKET_FIRESTORE_EMULATOR_HOST: process.env.REACT_APP_FIREBASE_ROCKET_FIRESTORE_EMULATOR_HOST,
    REACT_APP_FIREBASE_ROCKET_STORAGE_EMULATOR_HOST: process.env.REACT_APP_FIREBASE_ROCKET_STORAGE_EMULATOR_HOST,

    // firebase Rocket
    REACT_APP_ROCKET_API_KEY: process.env.REACT_APP_ROCKET_API_KEY,
    REACT_APP_ROCKET_APP_ID: process.env.REACT_APP_ROCKET_APP_ID,
    REACT_APP_ROCKET_AUTH_DOMAIN: process.env.REACT_APP_ROCKET_AUTH_DOMAIN,
    REACT_APP_ROCKET_MESSAGING_SENDER_ID: process.env.REACT_APP_ROCKET_MESSAGING_SENDER_ID,
    REACT_APP_ROCKET_PROJECT_ID: process.env.REACT_APP_ROCKET_PROJECT_ID,
    REACT_APP_ROCKET_STORAGE_BUCKET: process.env.REACT_APP_ROCKET_STORAGE_BUCKET,
    REACT_APP_ROCKET_MEASUREMENT_ID: process.env.REACT_APP_ROCKET_MEASUREMENT_ID,

    REACT_APP_GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY


} as const;
