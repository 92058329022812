import { Endpoint, FanDoc } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = {};

type Res = FanDoc[] | void;

type ReqQuery = {
  startAt: string;
  endBefore: string;
  startAtFanId?: string;
};

export type GetFansEndpoint = Endpoint<ReqBody, any, Res, ReqQuery>;

export const getFansEndpoint = {
  protocol: PROTOCOL.GET,
  url: () => `/fans`,
};
