import { Dependencies, ReduxStoreIntegration } from "@ttp/goods";

export const removeReducer = (
  dependencies: ReduxStoreIntegration & Dependencies
) => {
  const {
    Error,
    store,
    reducers,
    reduxToolkit: { combineReducers },
  } = dependencies;

  return async (key: string) => {
    try {
      delete reducers[key];

      const combinedReducers = combineReducers(reducers);

      store.replaceReducer(combinedReducers);
    } catch (e) {
      return new Error("Something unexpected happened in initMountainStore");
    }
  };
};
