import {
  ClientDependencies,
  ClientMountainState,
} from "@ttp/goods-config-client/types";
import {
  getSelector,
  mountReducer,
  removeReducer,
  updateAction,
} from "@ttp/goods";
import { MOUNTAINS } from "@ttp/goods-config-client/keys";

export const clientMountain = {
  actions: {
    mountReducer: (dependencies: ClientDependencies) =>
      mountReducer(dependencies)<ClientMountainState>(
        MOUNTAINS.CLIENT_MOUNTAIN,
        {
          isLoaded: false,
          isMobile: true,
          isUploadSequence: false,
          windowHeight: 0,
          windowWidth: 0,
        }
      ),
    removeReducer: (dependencies: ClientDependencies) =>
      removeReducer(dependencies)(MOUNTAINS.CLIENT_MOUNTAIN),
    update: (dependencies: ClientDependencies) =>
      updateAction<ClientMountainState>(
        dependencies,
        MOUNTAINS.CLIENT_MOUNTAIN
      ),
  },
  operations: {},
  selectors: {
    get: getSelector<ClientMountainState>(MOUNTAINS.CLIENT_MOUNTAIN),
  },
};
