import { Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = any;

type Params = { taskId: string };

type Res = any;

export type PostHandleTaskFirebaseFunctionEndpoint = Endpoint<
  ReqBody,
  Params,
  Res
>;

export const postHandleTaskFirebaseFunctionEndpoint = {
  protocol: PROTOCOL.POST,
  url: ({ taskId }: Params) => `/firebaseFunction/handleTask/${taskId}`,
};
