import { Endpoint, FanDoc, UserDoc } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = any;

type Params = any;

type Res = { user: UserDoc; fan: FanDoc };

export type ConnectUserEndpoint = Endpoint<ReqBody, Params, Res>;

export const connectUserEndpoint = {
  protocol: PROTOCOL.POST,
  url: () => `/users/connect`,
};
