import { AXIOS_LAUNCH_ROUTES } from "../keys";

// prettier-ignore
export const axiosLaunchRoutes = {
  [AXIOS_LAUNCH_ROUTES.ANALYTICS_GET_USER_EVENTS]:  { url: ()=>                    `/analytics/userEvents`              },
  [AXIOS_LAUNCH_ROUTES.ANALYTICS_POST_USER_EVENT]:  { url: ()=>                    `/analytics/userEvent`               },
  [AXIOS_LAUNCH_ROUTES.CAMPAIGNS]:                  { url: ()=>                    `/campaigns`                         },
  [AXIOS_LAUNCH_ROUTES.CAMPAIGN_EDIT]:              { url: (campaignId: string) => `/campaigns/${campaignId}`           },
  [AXIOS_LAUNCH_ROUTES.CAMPAIGN_GET]:               { url: (campaignId: string) => `/campaigns/${campaignId}`           },
  [AXIOS_LAUNCH_ROUTES.CAMPAIGN_POST]:              { url: ()=>                    `/campaigns`                         },
  [AXIOS_LAUNCH_ROUTES.CAMPAIGN_SEND]:              { url: (campaignId: string) => `/campaigns/${campaignId}/send`      },
  [AXIOS_LAUNCH_ROUTES.EDIT_FAN]:                   { url: (fanId: string) =>      `/fans/${fanId}`                     },
  [AXIOS_LAUNCH_ROUTES.FANS_LIST]:                  { url: ()=>                    `/fans`                              },
  [AXIOS_LAUNCH_ROUTES.FANS_POST]:                  { url: ()=>                    `/fans`                              },
  [AXIOS_LAUNCH_ROUTES.LIST_USERS]:                 { url: ()=>                    `/users`                             },
  [AXIOS_LAUNCH_ROUTES.SEGMENTS]:                   { url: ()=>                    `/segments`                          },
  [AXIOS_LAUNCH_ROUTES.SEGMENT_EDIT]:               { url: (segmentId: string) =>  `/segments/${segmentId}`             },
  [AXIOS_LAUNCH_ROUTES.SEGMENT_GET]:                { url: (segmentId: string) =>  `/segments/${segmentId}`             },
  [AXIOS_LAUNCH_ROUTES.SEGMENT_POST]:               { url: ()=>                    `/segments`                          },
  [AXIOS_LAUNCH_ROUTES.TRAVELS_GET_MODERATION_LIST]:{ url: ()=>                    `/travels/moderationList`            },
  [AXIOS_LAUNCH_ROUTES.TRAVELS_PATCH]:              { url: (travelId: string) =>   `/travels/${travelId}`               },
  [AXIOS_LAUNCH_ROUTES.TRAVELS_UPLOAD]:             { url: ()=>                    "/travels"                           },
  [AXIOS_LAUNCH_ROUTES.USERS_CONNECT]:              { url: ()=>                    `users/connect`                      },
};
