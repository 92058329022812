export enum NESTED_ADMIN_ROUTES {
  ADMIN_HOME = "*",
  USERS = "users",
  ADMINS = "admins",
  FANS = "fans",
  SEGMENTS = "segments",
  SEGMENTS_NEW = "segments/new",
  SEGMENTS_EDIT = "segments/edit",
  FANS_UPLOAD = "fans/upload",
  ANALYTICS = "analytics",
  MODERATION = "travels/moderation",
  TAGS = "travels/tags",
  TRAVELS = "travels",
  CAMPAIGNS = "campaigns",
  CAMPAIGNS_HOME = "campaigns",
  CAMPAIGNS_EDIT = "campaigns/edit",
  CAMPAIGNS_NEW = "campaigns/new",
  CAMPAIGNS_DRAFT = "campaigns/draft",
  CAMPAIGNS_SENT = "campaigns/sent",
  CURRENT_USER = "current-user",
  CAMPAIGN_SENT_CAMPAIGN = "campaigns/sent/*",
}

export enum ROUTE_KEYS {
  ACCOUNT_DETAILS = "/account-details",
  SIGN_IN = "/sign-in",
  UPLOAD = "/upload",
  ABOUT = "/about",
  ROOT = "/",
  EXPLORE = "/explore",
}
