import { Endpoint, SegmentDoc } from "@ttp/common/types";
import { PROTOCOL, SEGMENT_STATUS } from "@ttp/common/keys";
type ReqBody = {};

type Res = SegmentDoc[] | void;

type ReqQuery = {
  status: SEGMENT_STATUS;
};

export type GetSegmentsEndpoint = Endpoint<ReqBody, any, Res, ReqQuery>;

export const getSegmentsEndpoint = {
  protocol: PROTOCOL.GET,
  url: () => `/segments`,
};
