import { ANALYTIC_USER_EVENTS, PROTOCOL } from "@ttp/common/keys";
import { Endpoint, FanDoc, UserDoc, UserEventDoc } from "@ttp/common/types";

type ReqBody = {};

type Res = (UserEventDoc & { fanDoc?: FanDoc; userDoc: UserDoc })[] | void;

type ReqQuery = {
  startAt: string;
  endBefore: string;
  event: ANALYTIC_USER_EVENTS;
  startAtUserEventId?: string;
};

export type GetUserAnalyticsEndpoint = Endpoint<ReqBody, any, Res, ReqQuery>;

export const getUserAnalyticsEndpoint = {
  protocol: PROTOCOL.GET,
  url: () => `/analytics/userEvents`,
};
