import { CampaignParticipantDocUpdates, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = CampaignParticipantDocUpdates;
type Params = { fanId: string; campaignId: string };

type Res = void;

export type PatchCampaignParticipantFromTwilioEndpoint = Endpoint<
  ReqBody,
  Params,
  Res
>;

export const patchCampaignParticipantFromTwilioEndpoint = {
  protocol: PROTOCOL.PATCH,
  url: ({ fanId, campaignId }: Params) =>
    `/twilio/campaign-participant/${campaignId}/${fanId}`,
};
