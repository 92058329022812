import { createContext, useContext, useReducer } from "react";

const ImageContext = createContext({
  imagesByUrl: {},
  addImage: (url, string) => {},
});

export function ImageContextProvider({ children }) {
  const [imagesByUrl, dispatch] = useReducer(imageReducer, {});

  const addImage = (url, string) => {
    // dispatch action to add url to reducer
    dispatch({
      type: "addImage",
      payload: { [url]: string },
    });
  };
  return (
    <ImageContext.Provider value={{ imagesByUrl, addImage }}>
      {children}
    </ImageContext.Provider>
  );
}

export function useImageContext() {
  return useContext(ImageContext);
}

function imageReducer(state, action) {
  switch (action.type) {
    case "addImage": {
      return { ...state, ...action.payload };
    }
    default: {
      return { ...state };
    }
  }
}
