import { ANALYTIC_USER_EVENTS } from "@ttp/common";

export type AnalyticEventPayloads = {
  [ANALYTIC_USER_EVENTS.TRAVEL_UPLOADED]: {
    travelId: string;
  };
  [ANALYTIC_USER_EVENTS.TRAVEL_MODERATED_APPROVED]: {
    travelId: string;
    approvedBy: string;
  };
  [ANALYTIC_USER_EVENTS.CAMPAIGN_PARTICIPANT_UPDATED]: {
    campaignId: string;
    updates: Record<string, any>;
  };
  [ANALYTIC_USER_EVENTS.FAN_UNSUBSCRIBE]: {
    campaignId: string;
  };
};
