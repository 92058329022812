import { Dependencies, ReduxStoreIntegration } from "@ttp/goods";
import { Reducer } from "@reduxjs/toolkit";

export const injectReducer = (
  dependencies: ReduxStoreIntegration & Dependencies
) => {
  const {
    Error,
    store,
    reducers,
    reduxToolkit: { combineReducers },
  } = dependencies;

  return async (key: string, reducer: Reducer) => {
    try {
      reducers[key] = reducer;

      const combinedReducers = combineReducers(reducers);

      store.replaceReducer(combinedReducers);
    } catch (e) {
      return new Error("Something unexpected happened in initMountainStore");
    }
  };
};
