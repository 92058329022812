import {
  Dependencies,
  FirebaseClientFirestoreIntegration,
} from "@ttp/goods/types";

const filename = "clientAdd";

export const clientAdd = async (
  dependencies: Dependencies & FirebaseClientFirestoreIntegration,
  path: string,
  updateObject: Record<string, any>
) => {
  const { logger, firestore, Error } = dependencies;

  if (!logger || !firestore) throw `Missing dependencies in ${filename}`;

  try {
    const { collection, Firestore, addDoc } = firestore;

    await addDoc(collection(Firestore, path), updateObject);
  } catch (error) {
    logger.error("Unexpected error in client update", error);
    return new Error("Unexpected error in client update");
  }
};
