import { CampaignDoc, EditCampaignUpdates, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = EditCampaignUpdates;

type Params = any;

type Res = CampaignDoc | void;

export type PostCampaignEndpoint = Endpoint<ReqBody, Params, Res>;

export const postCampaignEndpoint = {
  protocol: PROTOCOL.POST,
  url: () => `/campaigns`,
};
