import {
  Endpoint,
  FanDoc,
  SegmentDoc,
  SegmentParticipantDoc,
} from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = any;

type Params = {
  segmentId: string;
};

type Res =
  | (SegmentDoc & {
      participants: {
        segmentParticipant: SegmentParticipantDoc;
        fan: FanDoc;
      }[];
    })
  | void;

type ReqQuery = { startAtSegmentParticipantId?: string };

export type GetSegmentEndpoint = Endpoint<ReqBody, Params, Res, ReqQuery>;

export const getSegmentEndpoint = {
  protocol: PROTOCOL.GET,
  url: ({ segmentId }: Params) => `/segment/${segmentId}`,
};
