import { ClientDependencies } from "@ttp/goods-config-client/types";
import { TravelsDoc } from "@ttp/common";

const errorName = "getMyTravels";
export const getMyTravels = (
  dependencies: Pick<
    ClientDependencies,
    "firestore" | "app" | "logger" | "rocketAuth"
  >
) => {
  const {
    firestore: { collection, query, where, Firestore, getDocs },
    rocketAuth,
    logger,
  } = dependencies;

  return async (): Promise<TravelsDoc[] | Error> => {
    try {
      // query for docs in this collection that are owned by this user
      // requires updates to rules to support query against docs that aren't private

      const { uid } = rocketAuth.currentUser;
      const travelsRef = collection(Firestore, "travels");
      const travelsQuery = query(travelsRef, where("creatorId", "==", uid));
      const travelsQuerySnap = await getDocs(travelsQuery);

      return travelsQuerySnap.docs.map((doc) => {
        return doc.data() as TravelsDoc;
      });
    } catch (e) {
      logger.error(e);
      return new Error("Something unexpected happened in " + errorName);
    }
  };
};
