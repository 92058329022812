import {
  getSegmentEndpoint,
  GetSegmentEndpoint,
  getSegmentsEndpoint,
  GetSegmentsEndpoint,
  patchSegmentEndpoint,
  PatchSegmentEndpoint,
  PostSegmentEndpoint,
  postSegmentEndpoint,
} from "@ttp/common";
import {
  createAxiosLaunchGetRequest as newGet,
  createAxiosLaunchRequest as newReq,
} from "@ttp/goods-config-client/utils/operationCreators";

export const segmentMountain = {
  operations: {
    getSegment: newGet<GetSegmentEndpoint>(getSegmentEndpoint),
    getSegments: newGet<GetSegmentsEndpoint>(getSegmentsEndpoint),
    patchSegment: newReq<PatchSegmentEndpoint>(patchSegmentEndpoint),
    postSegment: newReq<PostSegmentEndpoint>(postSegmentEndpoint),
  },
};
