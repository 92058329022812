import {
  Dependencies,
  FirebaseAdminDatabaseIntegration,
} from "@ttp/goods/types";

const filename = "adminUpdate";

export const adminUpdate = async (
  dependencies: Dependencies & FirebaseAdminDatabaseIntegration,
  path: string,
  updateObject: any
) => {
  const { logger, database, Error } = dependencies;

  if (!logger || !database || !Error)
    throw `Missing dependencies in ${filename}`;

  try {
    await database.ref(path).update(updateObject);
  } catch (error) {
    const message = `Unexpected error in ${filename}`;
    logger.error(message, error);
    return new Error(message);
  }
};
