import { AuthMountainState } from "@ttp/goods-config-client/types/state/authMountain";
import { ClientDependencies, MOUNTAINS } from "@ttp/goods-config-client";
import { updateAction } from "@ttp/goods";

export const connectClientAuthInstance = (dependencies: ClientDependencies) => {
  const updateAuthState = updateAction<AuthMountainState>(
    dependencies,
    MOUNTAINS.AUTH_MOUNTAIN
  );
  const { auth, rocketAuth } = dependencies;
  return (claims: string[]) => {
    return auth.onIdTokenChanged(rocketAuth, async (user) => {
      if (!user) {
        return updateAuthState({ isAuthLoaded: true, user: {} });
      }

      const {
        uid,
        email,
        emailVerified,
        displayName,
        isAnonymous,
        metadata,
        phoneNumber,
        photoURL,
        providerData,
        providerId,
      } = user.toJSON() as any;

      const tokenResult = await user.getIdTokenResult();
      const customClaims = {};

      let hasAdminClaims = false;

      if (claims && tokenResult.claims) {
        claims.forEach((claimsBucketKey) => {
          const claimsBucket = tokenResult.claims[claimsBucketKey] as string[];
          if (claimsBucketKey === "admin" && claimsBucket)
            hasAdminClaims = true;
          if (!claimsBucket) return;
          claimsBucket.forEach((claim) => {
            customClaims[claim] = true;
          });
        });
      }

      updateAuthState({
        isAuthLoaded: true,
        user: {
          displayName,
          email,
          emailVerified,
          hasAdminClaims,
          isAnonymous,
          metadata,
          phoneNumber,
          photoURL,
          providerData,
          providerId,
          uid,
          ...customClaims,
        },
      });
    });
  };
};
