export enum FIREBASE_COLLECTION_PATHS {
  ADMINS = "admins",
  FIREBASE_FUNCTION_TASKS = "firebase-function-tasks",
  CAMPAIGNS = "campaigns",
  TRAVELS = "travels",
  USERS = "users",
  USERS_ANALYTICS = "users-analytics",
  SEGMENTS = "segments",
  FANS = "fans",
  PLATFORM_CAMPAIGNS = "platform-campaigns",
}
export enum FIREBASE_PLATFORM_CAMPAIGNS {
  APPROVED_PROMO = "approved-promo",
}

export enum FIREBASE_STORAGE_ROOT_DIRECTORY_PATHS {
  TRAVELS = "travels",
}

export enum FIREBASE_SUB_COLLECTION_PATHS {
  CAMPAIGN_PARTICIPANT = "campaignParticipant",
  SEGMENT_PARTICIPANT = "segmentParticipant",
}
