import { configureStore, Reducer } from "@reduxjs/toolkit";
import { MOUNTAINS } from "@ttp/goods-config-client";
const reducer = (
  state = {
    overlookApp: "Online",
  },
  action: { type: any }
) => {
  switch (action.type) {
    case "SET_OFFLINE":
      return {
        ...state,
        overlookApp: "Offline",
      };

    default:
      return state;
  }
};
const reducers = { root: reducer } as { root: Reducer } & Record<
  MOUNTAINS,
  Reducer
>;
const store = configureStore({ reducer: reducers });

export const reduxToyota = {
  reducers,
  store,
};
