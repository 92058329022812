import { Endpoint, GetAllTravelsResponse } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = any;

type Params = any;

type Res = GetAllTravelsResponse;

export type GetAllTravelsEndpoint = Endpoint<ReqBody, Params, Res>;

export const getAllTravelsEndpoint = {
  protocol: PROTOCOL.GET,
  url: () => `/travels/all`,
};
