import {
  Dependencies,
  FirebaseClientDatabaseIntegration,
} from "@ttp/goods/types";

const filename = "clientUpdate";

export const clientUpdate = async (
  dependencies: Dependencies & FirebaseClientDatabaseIntegration,
  path: string,
  updateObject: Record<string, any>
) => {
  const { logger, database, Error } = dependencies;

  if (!logger || !database) throw `Missing dependencies in ${filename}`;

  try {
    const { ref, Database, update } = database;

    await update(ref(Database, path), updateObject);
  } catch (error) {
    logger.error("Unexpected error in client update", error);
    return new Error("Unexpected error in client update");
  }
};
