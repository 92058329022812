import { Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = any;

type Params = { travelId: string };

type Res = void;

export type DeleteTravelEndpoint = Endpoint<ReqBody, Params, Res>;

export const deleteTravelEndpoint = {
  protocol: PROTOCOL.DELETE,
  url: ({ travelId }: Params) => `/travels/${travelId}`,
};
