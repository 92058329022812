import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { GoogleMapOptions } from "types";
import React, { CSSProperties, ReactNode } from "react";
import { env } from "config";

const containerStyle = {
  height: "400px",
  width: "400px",
};

const fallbackCenter = {
  lat: 34.6838,
  lng: -86.7253,
};

// recommended that this is a const outside of components
const libraries = ["drawing", "places"];

type Props = {
  style?: CSSProperties;
  children?: ReactNode;
  center?: {
    lat: number;
    lng: number;
  };
  onApiLoaded?: (map: any) => any;
  options?: GoogleMapOptions;
};

export const ReactGoogleMapsWithoutZone = ({
  children,
  onApiLoaded,
  center = fallbackCenter,
  style,
  options,
}: Props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    // @ts-ignore
    libraries: libraries,
  });

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          /* @ts-ignore */
          googleMapURL={
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyDuhO9prPl3H9IufVH3utNti8BZeJhi5so&v=3.exp&libraries=geometry,drawing,places"
          }
          mapContainerStyle={style ? style : containerStyle}
          zoom={10}
          center={center}
          onLoad={(mapNode) => {
            if (onApiLoaded) onApiLoaded(mapNode);
          }}
          options={options}
        >
          {children}
        </GoogleMap>
      ) : (
        <div />
      )}
    </>
  );
};
