import { EditFanUpdates, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = Pick<EditFanUpdates, "subscriptionStatus">;

type Params = { phone: string };

type Res = void;

export type PatchFanFromTwilioEndpoint = Endpoint<ReqBody, Params, Res>;

export const patchFanFromTwilioEndpoint = {
  protocol: PROTOCOL.PATCH,
  url: ({ phone }: Params) => `/twilio/fanByPhone/${phone}`,
};
