import { Endpoint, GetModerationListResponse } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = any;

type Params = any;

type Res = GetModerationListResponse;

export type GetModerationListEndpoint = Endpoint<ReqBody, Params, Res>;

export const getModerationListEndpoint = {
  protocol: PROTOCOL.GET,
  url: () => `/travels/moderationList`,
};
