import { AxiosResponse } from "axios";
import { ClientDependencies } from "@ttp/goods-config-client/types";

export const helloWorld = (dependencies: ClientDependencies) => {
  const { axios, logger } = dependencies;
  return async (shouldLog: boolean) => {
    if (shouldLog) {
      logger.log("Hello world client mountain is workin'");
    }

    const result = await axios.get(
      "http://localhost:8080/dev/token/s4NIYDeHIeVmyHogv8bAwPHCLFh1"
    );

    return result as AxiosResponse<string>;
  };
};
