import { ACTIONS } from "@ttp/goods/keys";
import { Dependencies, ReduxStoreIntegration } from "@ttp/goods";

export const updateAction = <Payload = any>(
  dependencies: Dependencies & ReduxStoreIntegration,
  path: string
) => {
  const { Error, store } = dependencies;
  return (payload: Partial<Payload>) => {
    try {
      store.dispatch({ payload, type: `${path}/${ACTIONS.UPDATE}` });
    } catch (e) {
      return new Error("Something unexpected happened in dispatchAction");
    }
  };
};
