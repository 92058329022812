import {
  DocWithLastInteraction,
  FirestoreDocWithLastInteraction,
} from "@ttp/common/types/firestore";
import { SUBSCRIPTION_STATUS } from "@ttp/common/keys";

export type EditFanUpdates = Partial<
  Omit<FanDoc, typeof restrictedFanFields[number]>
>;
export type FanDoc = DocWithLastInteraction<{
  phone: string;
  subscriptionStatus: SUBSCRIPTION_STATUS;

  firstName?: string;
  lastName?: string;
  linkedUserIdPhone?: string;
  lastCampaign?: string;

  fanId: string;
}>;

export type FanFirestoreDoc = FirestoreDocWithLastInteraction<FanDoc>;

export type NewFans = Partial<
  Omit<FanDoc, typeof restrictedFanFields[number]>
>[];

export const restrictedFanFields = [
  "fanId",
  "createdAt",
  "lastInteractionAt",
  "interactions",
] as const;
