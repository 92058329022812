import { Endpoint, FanDoc } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = {
  fanIds: string[];
};

type Res = FanDoc[] | void;

export type ListFansByIdEndpoint = Endpoint<ReqBody, any, Res>;

export const listFansByIdEndpoint = {
  protocol: PROTOCOL.POST,
  url: () => `/fans/listById`,
};
