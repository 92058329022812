import { CAMPAIGN_STATUS } from "@ttp/common/keys";
import { firestore } from "firebase-admin";

// A subcollection will grab a copy of the segment
export type CampaignDoc = {
  customParams: Record<string, any>;
  segmentId: string;
  name: string;
  status: CAMPAIGN_STATUS;
  flowId: string;
  flowFrom: string;

  campaignId: string;
  createdAt: string;
};

export type CampaignFirestoreDoc = Omit<CampaignDoc, "createdAt"> & {
  createdAt: firestore.Timestamp;
};

export type EditCampaignUpdates = Partial<
  Omit<CampaignDoc, typeof restrictedCampaignDocFields[number]>
>;

export const restrictedCampaignDocFields = ["campaignId", "createdAt"] as const;
