import {
  deleteAdminEndpoint,
  DeleteAdminEndpoint,
  getAdminsEndpoint,
  GetAdminsEndpoint,
  patchAdminEndpoint,
  PatchAdminEndpoint,
  postAdminEndpoint,
  PostAdminEndpoint,
} from "@ttp/common";
import {
  createAxiosLaunchGetRequest as newGet,
  createAxiosLaunchRequest as newReq,
} from "@ttp/goods-config-client/utils/operationCreators";

export const adminMountain = {
  operations: {
    deleteAdmin: newReq<DeleteAdminEndpoint>(deleteAdminEndpoint),
    getAdmins: newGet<GetAdminsEndpoint>(getAdminsEndpoint),
    patchAdmin: newReq<PatchAdminEndpoint>(patchAdminEndpoint),
    postAdmin: newReq<PostAdminEndpoint>(postAdminEndpoint),
  },
};
