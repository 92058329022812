import {
  ClientDependencies,
  HelloWorldState,
} from "@ttp/goods-config-client/types";
import {
  getSelector,
  mountReducer,
  removeReducer,
  updateAction,
} from "@ttp/goods";
import { helloWorld } from "@ttp/goods-config-client/utils/operations";
import { MOUNTAINS } from "@ttp/goods-config-client/keys";

export const helloWorldMountain = {
  actions: {
    mountReducer: (dependencies: ClientDependencies) =>
      mountReducer(dependencies)<HelloWorldState>(MOUNTAINS.HELLO_WORLD_MOUNTAIN, {
        hellos: [],
      }),
    removeReducer: (dependencies: ClientDependencies) =>
      removeReducer(dependencies)(MOUNTAINS.HELLO_WORLD_MOUNTAIN),
    update: (dependencies: ClientDependencies) =>
      updateAction<HelloWorldState>(dependencies, MOUNTAINS.HELLO_WORLD_MOUNTAIN),
  },
  operations: {
    helloWorld,
  },
  selectors: {
    get: getSelector<HelloWorldState>(MOUNTAINS.HELLO_WORLD_MOUNTAIN),
  },
};
