import {
  CampaignDoc,
  CampaignParticipantDoc,
  Endpoint,
  FanDoc,
} from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = {};

type Params = { campaignId: string };

type Res =
  | (CampaignDoc & {
      segmentName: string;
      participants: (CampaignParticipantDoc & FanDoc)[];
    })
  | void;

type ReqQuery = {
  startAtCampaignParticipantId?: string;
};

export type GetSentCampaignEndpoint = Endpoint<ReqBody, Params, Res, ReqQuery>;

export const getSentCampaignEndpoint = {
  protocol: PROTOCOL.GET,
  url: ({ campaignId }: Params) => `/sent-campaigns/${campaignId}`,
};
