import { AxiosResponse } from "axios";
import { ClientDependencies } from "@ttp/goods-config-client";
import { Endpoint, PROTOCOL } from "@ttp/common";

type EndpointType<params> = {
  protocol: PROTOCOL;
  url: (params: params) => string;
};

type Dependencies = Pick<ClientDependencies, "axiosLaunch" | "logger">;

export const createAxiosLaunchGetRequest = <E extends Endpoint<any, any, any>>(
  endpoint: EndpointType<E["reqParams"]>
) => {
  const { protocol, url } = endpoint;

  return (dependencies: Dependencies) => {
    const { axiosLaunch } = dependencies;

    return (
      params: E["reqParams"] = {},
      queries: E["reqQuery"] = {}
    ): Promise<AxiosResponse<E["res"]>> => {
      const queryString = new URLSearchParams(queries).toString();

      return axiosLaunch[protocol](
        `${url(params)}?${queryString ? queryString : ""}`
      );
    };
  };
};

export const createAxiosLaunchRequest = <E extends Endpoint<any, any, any>>(
  endpoint: EndpointType<E["reqParams"]>
) => {
  const { protocol, url } = endpoint;

  return (dependencies: Dependencies) => {
    const { axiosLaunch } = dependencies;

    return (
      params: E["reqParams"] = {},
      body: E["reqBody"] = {}
    ): Promise<AxiosResponse<E["res"]>> => {
      return axiosLaunch[protocol](url(params), body);
    };
  };
};
