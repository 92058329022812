import { FlexColumn } from "components";

import { sizes } from "config";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientMountain } from "@ttp/goods-config-client";

export const AboutPage = () => {
  const navigate = useNavigate();

  const { isMobile } = useSelector(clientMountain.selectors.get);

  return (
    <FlexColumn
      alignItems={"center"}
      paddingTop={20}
      paddingRight={15}
      paddingLeft={15}
      paddingBottom={isMobile ? 150 : 0}
    >
      <FlexColumn
        gap={10}
        maxWidth={isMobile ? sizes.aboutBody - 100 : sizes.aboutBody}
      >
        <Typography variant={"h3"}>About Trash Panda Travels</Typography>

        <Typography>
          The Rocket City Trash Pandas logo has been coast to coast and all over
          the globe. We've sent merchandise to all 50 states and over 40
          countries. Now, we're bringing fans together in one spot to share
          their experiences with the most meteoric brand in Minor League
          Baseball.
        </Typography>

        <Typography>
          The Trash Pandas Travels campaign is now under way! Whether you're on
          vacation, taking a trip abroad, or just want to represent your
          hometown, anyone can upload photos here to showcase Sprocket!
        </Typography>

        <Typography>
          Want to see where else other fans have tagged their location? Simply
          scroll around the <Link onClick={() => navigate("/")}>{"map"}</Link>{" "}
          to explore the amazing reach of the Trash Pandas brand and check out
          all the adventures!
        </Typography>

        <Typography>
          So wherever you're headed next, make sure you pack your bags with
          plenty of Trash Pandas gear and hit us with your best shots. Don't
          have any apparel yet? You can check out our award-winning selection 24
          hours a day, 7 days a week at{" "}
          <Link
            target="_blank"
            href="https://trashpandas.milbstore.com/"
            referrerPolicy={"no-referrer"}
            rel="noreferrer"
          >
            {"shoptrashpandas.com"}
          </Link>
          ! We can't wait to see all the incredible places we'll land.
        </Typography>
      </FlexColumn>
    </FlexColumn>
  );
};
