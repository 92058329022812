import { UploadFormUtil } from "content";
import { FlexColumn } from "components";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { sizes } from "config";
import TextField from "@mui/material/TextField";

const StyledPaper = styled(Paper)({
  width: sizes.uploadPreview,
  height: sizes.uploadPreview,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
});

const StyledTextField = styled(TextField)({
  height: 200,
  width: sizes.uploadPreview,
});

export const ImageCaption = ({
  uploadForm,
}: {
  uploadForm: UploadFormUtil;
}) => {
  const { formData, updateFormData } = uploadForm;
  const { caption, croppedUploadBase64 } = formData;
  return (
    <FlexColumn
      width={"100%"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      gap={20}
      paddingTop={20}
    >
      <StyledPaper elevation={6}>
        <img
          alt={"Trash Panda Travels logo"}
          src={croppedUploadBase64.value}
          height={sizes.uploadPreview}
          width={sizes.uploadPreview}
          style={{ objectFit: "contain" }}
        />
      </StyledPaper>

      <StyledTextField
        id={"upload-field-to-focus"}
        multiline
        minRows={2}
        maxRows={2}
        label={"Caption"}
        value={caption.value}
        onChange={({ target: { value: caption } }) =>
          updateFormData({ caption })
        }
      />
    </FlexColumn>
  );
};
