import { EditSegmentUpdates, Endpoint, SegmentDoc } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = Omit<EditSegmentUpdates, "removeRows">;

type Params = any;

type Res = SegmentDoc;

export type PostSegmentEndpoint = Endpoint<ReqBody, Params, Res>;

export const postSegmentEndpoint = {
  protocol: PROTOCOL.POST,
  url: () => `/segment`,
};
