import React, { ReactNode } from "react";
import BottomAppBar from "components/BottomAppBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AppDrawerSider } from "components/AppDrawerSider";
import { ResizeHandler } from "components/ResizeHandler";
import { clientMountain } from "@ttp/goods-config-client";
import { useSelector } from "react-redux";
import { AutoDiv } from "components";

export const ResponsiveMUILayout = ({ children }: { children: ReactNode }) => {
  const {
    selectors: { get },
  } = clientMountain;
  const { isMobile, isUploadSequence } = useSelector(get);
  const darkTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#007dc3",
      },
      secondary: {
        main: "#ef3e42",
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <ResizeHandler />

      {!isUploadSequence && <AppDrawerSider />}
      <AutoDiv marginLeft={isUploadSequence || isMobile ? 0 : 250}>
        {children}
        <BottomAppBar />
      </AutoDiv>
    </ThemeProvider>
  );
};
