import { Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = { travelId: string };

type Params = any;

type Res = { success: boolean };

export type SendApprovedPromoEndpoint = Endpoint<ReqBody, Params, Res>;

export const sendApprovedPromoEndpoint = {
  protocol: PROTOCOL.POST,
  url: () => `/approved-promo-config/send`,
};
