export enum CAMPAIGN_STATUS {
  DRAFT = "draft",
  SENDING_PAUSED = "sending_paused",
  SENDING = "sending",
  SENT = "sent",
}
export enum SEGMENT_STATUS {
  ACTIVE = "active",
  ARCHIVED = "archived",
}
export enum SUBSCRIPTION_STATUS {
  UNKNOWN = "unknown",
  CONSENTED = "consented",
  REMOVED = "removed",
}

export enum TRAVEL_STATUS {
  READY_FOR_UPLOAD = "ready-for-upload",
  READY_FOR_MODERATION = "ready-for-moderation",
  PUBLIC = "public",
  HIDDEN = "hidden",
}
