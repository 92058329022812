import {
  getFansEndpoint,
  GetFansEndpoint,
  listFansByIdEndpoint,
  ListFansByIdEndpoint,
  patchFanEndpoint,
  PatchFanEndpoint,
  postFansEndpoint,
  PostFansEndpoint,
} from "@ttp/common";
import {
  createAxiosLaunchGetRequest as newGet,
  createAxiosLaunchRequest as newReq,
} from "@ttp/goods-config-client/utils/operationCreators";

export const fanMountain = {
  operations: {
    getFans: newGet<GetFansEndpoint>(getFansEndpoint),
    listFans: newReq<ListFansByIdEndpoint>(listFansByIdEndpoint),
    patchFan: newReq<PatchFanEndpoint>(patchFanEndpoint),
    postFans: newReq<PostFansEndpoint>(postFansEndpoint),
  },
};
