import { EditFanUpdates, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = EditFanUpdates;

type Params = { fanId: string };

type Res = void;

export type PatchFanEndpoint = Endpoint<ReqBody, Params, Res>;

export const patchFanEndpoint = {
  protocol: PROTOCOL.PATCH,
  url: ({ fanId }: Params) => `/fans/${fanId}`,
};
