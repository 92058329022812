import * as React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import { ReactComponent as LogoSVG } from "content/images/logo-small.svg";
import Divider from "@mui/material/Divider";
import { authMountain, clientMountain } from "@ttp/goods-config-client";
import { useSelector } from "react-redux";
import { dependencies } from "content";
import {
  LoginRounded,
  Logout,
  LaunchOutlined,
  Person2Rounded,
  Explore,
  HelpOutline,
} from "@mui/icons-material";
import { firebaseRocket } from "loaders";
import { useNavigate } from "react-router-dom";
import { ROUTE_KEYS } from "keys";
import logoWideBG from "content/images/tpt-logo-wide-bg.png";
import { AutoDiv } from "components/02-layout";

export const AppDrawerSider = () => {
  const {
    selectors: { get },
    actions: { update },
  } = clientMountain;

  const {
    user = {},
    userDoc,
    fanDoc,
    isAuthLoaded,
  } = useSelector(authMountain.selectors.get);
  const navigate = useNavigate();

  const setIsDrawerOpen = (isDrawerOpen: boolean) =>
    update(dependencies)({ isDrawerOpen });
  const { isDrawerOpen, isMobile } = useSelector(get);

  const shouldRenderAccountDetails =
    isAuthLoaded && !user.isAnonymous && fanDoc && userDoc;
  const shouldRenderSignIn = user.isAnonymous;
  const shouldRenderLogOut = isAuthLoaded && !user.isAnonymous;

  return (
    <React.Fragment>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        anchor={"left"}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setIsDrawerOpen(false)}
          onKeyDown={() => setIsDrawerOpen(false)}
        >
          <List sx={{ paddingTop: 0 }}>
            <AutoDiv paddingBottom={25} paddingTop={0}>
              <img
                src={logoWideBG}
                width={250}
                style={{ objectFit: "scale-down" }}
              />
            </AutoDiv>
            {shouldRenderSignIn && (
              <ListItem key={"Sign In"} disablePadding>
                <ListItemButton onClick={() => navigate(ROUTE_KEYS.SIGN_IN)}>
                  <ListItemIcon>
                    <LoginRounded />
                  </ListItemIcon>
                  <ListItemText primary={"Sign In"} />
                </ListItemButton>
              </ListItem>
            )}
            {shouldRenderAccountDetails && (
              <ListItem key={"Account Details"} disablePadding>
                <ListItemButton
                  onClick={() => navigate(ROUTE_KEYS.ACCOUNT_DETAILS)}
                >
                  <ListItemIcon>
                    <Person2Rounded />
                  </ListItemIcon>
                  <ListItemText primary={"Account Details"} />
                </ListItemButton>
              </ListItem>
            )}

            <ListItem key={"Explore"} disablePadding>
              <ListItemButton onClick={() => navigate("/")}>
                <ListItemIcon>
                  <Explore />
                </ListItemIcon>
                <ListItemText primary={"Map"} />
              </ListItemButton>
            </ListItem>

            <ListItem key={"Shop"} disablePadding>
              <ListItemButton
                onClick={() => {
                  const shopUrl = "https://trashpandas.milbstore.com/";

                  window.open(shopUrl, "_blank", "noopener noreferrer");
                }}
              >
                <ListItemIcon>
                  <LogoSVG height={24} width={24} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      Shop
                      <LaunchOutlined sx={{ height: 16 }} />
                    </>
                  }
                />
              </ListItemButton>
            </ListItem>

            <ListItem key={"About"} disablePadding>
              <ListItemButton onClick={() => navigate(ROUTE_KEYS.ABOUT)}>
                <ListItemIcon>
                  <HelpOutline />
                </ListItemIcon>
                <ListItemText primary={"About"} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          {shouldRenderLogOut && (
            <ListItem key={"Log Out"} disablePadding>
              <ListItemButton
                onClick={() => {
                  firebaseRocket.auth.signOut();
                  navigate("/");
                }}
              >
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary={"Log Out"} />
              </ListItemButton>
            </ListItem>
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
};
