import { AuthClaims, Endpoint } from "@ttp/common/types";
import { ERROR_CODES, PROTOCOL } from "@ttp/common/keys";

type ReqBody = { email } & AuthClaims;

type Params = any;

type Res = void | {
  errorCode: ERROR_CODES.ADMIN_ALREADY_EXISTS | ERROR_CODES.USER_NOT_FOUND;
};

export type PostAdminEndpoint = Endpoint<ReqBody, Params, Res>;

export const postAdminEndpoint = {
  protocol: PROTOCOL.POST,
  url: () => `/admins`,
};
