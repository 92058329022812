import React, { useEffect, useRef, useState } from "react";
import {
  AutoDiv,
  FlexRow,
  ImageGridCard,
  ReactGoogleMapsWithoutZone,
  StyledCardMedium,
  TravelsCard,
} from "components";
import { GoogleMapOptions } from "types";
import { SESSION_STORAGE_KEYS } from "keys";
import { clientMountain, travelMountain } from "@ttp/goods-config-client";
import { dependencies } from "content";
import { useSelector } from "react-redux";
import * as geofire from "geofire-common";
import { TRAVEL_CARD_VARIANTS } from "keys/TRAVEL_CARD_VARIANTS";
import Carousel from "react-material-ui-carousel";
import { geoData, TravelsDoc } from "@ttp/common";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { ReactComponent as TPTLogoWide } from "content/images/tpt-logo-wide.svg";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { sizes } from "config";

export const ExplorePage = () => {
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const [establishedListener, setEstablishedListener] = useState<any>(null);
  const isFetchingRef = useRef(false);

  const markersRef = useRef<Record<string, google.maps.Marker>>({});
  const markerClustererRef = useRef<MarkerClusterer | null>(null);
  const markerMapInstanceRef = useRef<any>(undefined);

  const [mapReady, setMapReady] = useState(false);
  const [mapReadyLatest, setMapReadyLatest] = useState(Date.now());
  const [activeCard, setActiveCard] = useState<string | undefined>();

  const { publicTravels = [] } = useSelector(travelMountain.selectors.get);

  const [publicTravelsMap, setPublicTravelsMap] = useState({});

  const [hasLoadedTutorialCookie, setHasLoadedTutorialCookie] = useState(false);
  const [isTutorialCompleted, setIsTutorialCompleted] = useState(false);

  useEffect(() => {
    const isComplete = localStorage.getItem("isTutorialCompleted") === "true";

    setIsTutorialCompleted(isComplete);
    setHasLoadedTutorialCookie(true);
  }, []);

  const handleCloseTutorial = () => {
    localStorage.setItem("isTutorialCompleted", "true");
    setIsTutorialCompleted(true);
  };

  useEffect(() => {
    const newMap = publicTravels.reduce((a, b) => {
      const {
        content: { geoData },
      } = b;
      const key = geoData.lat + "^" + geoData.lng;
      if (!a[key]) return { ...a, [key]: [b] };
      const result = { ...a };
      result[key].push(b);
      return result;
    }, {});

    setPublicTravelsMap({ ...publicTravelsMap, ...newMap });
  }, [publicTravels]);

  const { isMobile } = useSelector(clientMountain.selectors.get);

  const cardClickHandler = (mapInstance, { lat, lng }: geoData) => {
    setActiveCard(lat + "^" + lng);
    mapInstance.setCenter({ lat, lng });

    const fullWidth = window.innerWidth;
    const halfFullWidth = fullWidth / 2;
    const widthOfSVG = isMobile ? 150 : 200;
    const paddingOfSVG = isMobile ? 15 * 2 : 25 * 2;
    const nonUsableLeftSpace = widthOfSVG + paddingOfSVG;
    const nonUsableLeftSpaceBuffer = 25;
    const nonUsableLeftSpaceTotal =
      nonUsableLeftSpace + nonUsableLeftSpaceBuffer;
    const isCenterInNonUsableSpace = Boolean(
      fullWidth / 2 < nonUsableLeftSpaceTotal
    );

    const usableRightSpace = fullWidth - nonUsableLeftSpaceTotal;
    const halfUsableRightSpace = usableRightSpace / 2;
    const pointToRecenter = nonUsableLeftSpaceTotal + halfUsableRightSpace;
    const xAdjustForReCenter = pointToRecenter - halfFullWidth;

    const xAdjust = isCenterInNonUsableSpace ? -xAdjustForReCenter : 0;

    // Pan behavior on active card click
    const fullHeight = window.innerHeight;
    const markerY = fullHeight / 2;
    const cardHeight = isMobile ? 300 : 460;
    const cardTopOffset = isMobile ? 150 : 200;
    const cardBottomOffset = 100;
    const remainingSpace = fullHeight - cardHeight - cardBottomOffset;
    const newY = cardTopOffset / 2;
    const mapIconHalfHeight = 25;
    const panAmount = markerY - newY;

    mapInstance.panBy(xAdjust, panAmount);
  };

  useEffect(() => {
    if (!mapInstance) return;

    mapInstance.addListener("click", () => {
      setActiveCard(undefined);
    });
  }, [mapInstance]);
  useEffect(() => {
    // sometimes google map loads twice, so we need to clear the markers ref
    if (markerMapInstanceRef.current !== mapReadyLatest) {
      markersRef.current = {};
    }

    markerMapInstanceRef.current = mapReadyLatest;

    const markersLength = Object.keys(markersRef.current).length;
    const needsUpdate = markersLength < publicTravels.length;

    if (!mapInstance || !needsUpdate || !markerClustererRef.current) {
      return;
    }

    publicTravels
      .slice(markersRef.current.length ? markersLength - 1 : 0)
      .forEach((travelDoc) => {
        if (markersRef.current[travelDoc.travelId]) return;
        // Origins, anchor positions and coordinates of the marker increase in the X
        // direction to the right and in the Y direction down.
        const image = {
          // The anchor for this image is the base of the flagpole at (x, y).
          anchor: new google.maps.Point(31, 102),

          // The origin for this image is (0, 0).
          origin: new google.maps.Point(0, 0),

          // This marker is x pixels wide by y pixels high.
          size: new google.maps.Size(59, 120),

          url: require("../content/images/map-pin.png"),
        };

        const marker = new google.maps.Marker({
          map: mapInstance,
          position: travelDoc.content.geoData,
          title: "Trash Panda Travel - " + travelDoc.content.signature,
          icon: image,
          clickable: true,
        });
        google.maps.event.addListener(marker, "click", () => {
          cardClickHandler(mapInstance, travelDoc.content.geoData);
        });
        markersRef.current[travelDoc.travelId] = marker;
        if (!markerClustererRef.current) return;
        markerClustererRef.current.addMarker(marker);
      });
  }, [publicTravels, mapInstance, mapReadyLatest, markerClustererRef.current]);

  useEffect(() => {
    // cleanup

    window.scrollTo(0, 1);
    return () => {
      markersRef.current = {};
      markerClustererRef.current = null;
      isFetchingRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!mapInstance) return;
    const centerCallback = () => {
      const bounds = mapInstance.getBounds();
      if (!bounds) return;
      const ne = bounds.getNorthEast().toJSON();
      const sw = bounds.getSouthWest().toJSON();

      const distanceInKm = geofire.distanceBetween(
        [ne.lat, ne.lng],
        [sw.lat, sw.lng]
      );

      const center = mapInstance.getCenter();
      if (!center) return;
      const sanitizedCenter = new google.maps.LatLng(
        center.lat(),
        center.lng(),
        false
      );
      const { lat, lng } = sanitizedCenter.toJSON();

      if (isFetchingRef.current) return;
      isFetchingRef.current = true;
      travelMountain.operations
        .getTravelsByGeoHash(dependencies)({
          lat,
          lng,
          radiusInM: distanceInKm * 1000,
        })
        .then(() => {
          isFetchingRef.current = false;
        })
        .catch(() => {
          isFetchingRef.current = false;
        });
    };

    if (establishedListener) {
      google.maps.event.removeListener(establishedListener);
    }

    const identifier = mapInstance?.addListener("dragend", centerCallback);
    setEstablishedListener(identifier);
    return () => {
      google.maps.event.removeListener(identifier);
    };
  }, [mapInstance]);

  useEffect(() => {
    if (!mapInstance) return;

    const center = mapInstance.getCenter();
    if (!center) return;
    const { lat, lng } = center.toJSON();
    if (isFetchingRef.current) return;
    isFetchingRef.current = true;

    travelMountain.operations
      .getTravelsByGeoHash(dependencies)({
        lat,
        lng,
        radiusInM: 500 * 1000,
      })
      .then(() => {
        isFetchingRef.current = false;
      })
      .catch(() => {
        isFetchingRef.current = false;
      });
  }, [mapInstance]);

  const handleApiLoaded = (map: google.maps.Map) => {
    setMapReady(true);
    setMapInstance(map);
    setMapReadyLatest(Date.now());
    markerClustererRef.current = new MarkerClusterer({
      map,
      renderer: {
        render: (cluster, stats, map) =>
          new google.maps.Marker({
            position: cluster.position,
            // adjust zIndex to be above other markers
            map,
            zIndex: Number(google.maps.Marker.MAX_ZINDEX),
            title: `${cluster.count} Trash Panda Travels`,
            icon: {
              // The anchor for this image is the base of the flagpole at (x, y).
              anchor: new google.maps.Point(31, 102),

              // The origin for this image is (0, 0).
              origin: new google.maps.Point(0, 0),

              // This marker is x pixels wide by y pixels high.
              size: new google.maps.Size(59, 120),

              url: require("../content/images/map-cluster.png"),
            },
            clickable: true,
          }),
      },
      onClusterClick: async (event, cluster, map) => {
        event.stop();

        const clusterGeoData = cluster.position.toJSON();
        cardClickHandler(map, clusterGeoData);

        const clusterKey = clusterGeoData.lat + "^" + clusterGeoData.lng;
        const latestPublicTravelsMap: any = await new Promise((resolve) => {
          setPublicTravelsMap((prevState) => {
            resolve(prevState);
            return prevState;
          });
        });

        if (latestPublicTravelsMap[clusterKey]) return;
        if (!cluster.markers) return;

        // loop thru clusters
        // get their publicTravels Map key
        // munge them together into a super key
        // add to the public travels map

        const loggedSet = new Set();

        const clusterTravels: any[] = [];

        for (const marker of cluster.markers) {
          const mark = marker as google.maps.Marker;
          if (!mark) continue;

          const markerPosition = mark.getPosition();

          if (!markerPosition) {
            continue;
          }

          const { lat, lng } = markerPosition.toJSON();

          const key = lat + "^" + lng;
          if (loggedSet.has(key)) continue;

          const markerTravels = latestPublicTravelsMap[key];
          if (!markerTravels || markerTravels.length === 0) {
            continue;
          }

          loggedSet.add(key);
          clusterTravels.push(...markerTravels);
        }

        setPublicTravelsMap({
          ...latestPublicTravelsMap,
          [clusterKey]: clusterTravels,
        });
      },
    });

    sessionStorage.setItem(
      SESSION_STORAGE_KEYS.HAS_GOOGLE_MAPS_LOADED_ONCE,
      "true"
    );
  };

  const style = {
    height: "100%",
    width: "100%",
  } as React.CSSProperties;

  const options = {
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    maxZoom: 12,
    minZoom: 3,
  } as GoogleMapOptions;

  return (
    <AutoDiv
      width={"100%"}
      height={`100vh`}
      position={"relative"}
      touchAction={"none"}
      overflowY={"hidden"}
    >
      {hasLoadedTutorialCookie && !isTutorialCompleted && (
        <AutoDiv
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={`100vh`}
          display={"flex"}
          justifyContent={"center"}
          alignItems={isMobile ? "flex-start" : "center"}
          backgroundColor={"rgba(0,0,0,0.45)"}
          zIndex={10000}
          onClick={() => {
            handleCloseTutorial();
          }}
        >
          <AutoDiv
            width={isMobile ? 350 : 440}
            zIndex={10000}
            opacity={1}
            marginTop={isMobile ? 20 : undefined}
            paddingLeft={isMobile ? 10 : 20}
            paddingRight={isMobile ? 10 : 20}
            paddingBottom={isMobile ? 10 : 20}
            paddingTop={isMobile ? 5 : 10}
            backgroundColor={"white"}
          >
            <img
              width={isMobile ? 310 : 400}
              alt={"Trash Panda Travels logo"}
              src={"assets/banner.png"}
              style={{ objectFit: "contain" }}
            />
            <Typography variant={"h4"}>
              Welcome to Trash Pandas Travels!
            </Typography>

            <Typography sx={{ paddingBottom: 2 }}>
              We want to see where in the world you've been with the best merch
              in Minor League Baseball.
            </Typography>

            <ol>
              <li>
                <Typography sx={{ paddingBottom: 1 }}>
                  Click the + button to upload a photo of yourself wearing Trash
                  Pandas gear
                </Typography>
              </li>
              <li>
                <Typography sx={{ paddingBottom: 1 }}>
                  Tell us a little about the image with a caption
                </Typography>
              </li>
              <li>
                <Typography sx={{ paddingBottom: 2 }}>
                  Type in the location where the picture was taken and put your
                  pin on the map
                </Typography>
              </li>
            </ol>

            <Typography sx={{ paddingBottom: 2 }}>
              You're all set from there! Each user who uploads will also receive
              a commemorative patch, while supplies last.
            </Typography>

            <FlexRow justifyContent={"flex-end"}>
              <Button variant={"contained"} onClick={handleCloseTutorial}>
                OK!
              </Button>
            </FlexRow>
          </AutoDiv>
        </AutoDiv>
      )}
      <ReactGoogleMapsWithoutZone
        style={style}
        onApiLoaded={handleApiLoaded}
        center={undefined}
        options={options}
      />
      <AutoDiv
        position={"fixed"}
        bottom={isMobile ? undefined : 75}
        top={isMobile ? 15 : undefined}
        left={isMobile ? 15 : 25}
      >
        <TPTLogoWide
          height={isMobile ? 90 : 120}
          width={isMobile ? 150 : 200}
        />
      </AutoDiv>

      <AutoDiv
        transition={"background-color 1s ease 1s"}
        position={"absolute"}
        backgroundColor={mapReady ? undefined : "black"}
        top={-40} // not sure why we need to do this?
        left={0}
        height={`100vh`}
        width={"100%"}
        pointerEvents={"none"}
        zIndex={900}
      />

      {activeCard && publicTravelsMap[activeCard] && (
        <FlexRow
          position={"absolute"}
          top={isMobile ? 150 : 200}
          width={"100%"}
          justifyContent={"center"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setActiveCard(undefined);
          }}
        >
          {publicTravelsMap[activeCard].length === 1 ? (
            <AutoDiv maxWidth={"fit-content"}>
              <TravelsCard
                variant={TRAVEL_CARD_VARIANTS.LARGE}
                location={publicTravelsMap[activeCard][0].content.location}
                src={publicTravelsMap[activeCard][0].src}
                body={publicTravelsMap[activeCard][0].content.description}
                signature={publicTravelsMap[activeCard][0].content.signature}
                onClick={(e) => e.stopPropagation()}
                onClickClose={() => setActiveCard(undefined)}
              />
            </AutoDiv>
          ) : publicTravelsMap[activeCard].length < 8 ? (
            <AutoDiv
              width={"100%"}
              onClick={() => setActiveCard(undefined)}
              marginRight={isMobile ? 10 : 0}
              marginLeft={isMobile ? 10 : 0}
              maxWidth={500}
            >
              <AutoDiv
                minWidth={!isMobile ? 400 : "100%"}
                position={"relative"}
              >
                <IconButton
                  style={{
                    color: "black",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 5000,
                  }}
                  onClick={() => setActiveCard(undefined)}
                >
                  <Close />
                </IconButton>
                <Carousel
                  navButtonsAlwaysVisible={isMobile}
                  animation={"slide"}
                >
                  {publicTravelsMap[activeCard].map(
                    ({
                      content: { location, signature, description },
                      src,
                      travelId,
                    }: TravelsDoc) => {
                      return (
                        <TravelsCard
                          isNoElevation
                          isNoClose
                          key={travelId}
                          onClick={(e) => e.stopPropagation()}
                          variant={TRAVEL_CARD_VARIANTS.LARGE}
                          location={location}
                          src={src}
                          body={description}
                          signature={signature}
                        />
                      );
                    }
                  )}
                </Carousel>
              </AutoDiv>
            </AutoDiv>
          ) : (
            <AutoDiv
              width={"100%"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              marginRight={isMobile ? 10 : 0}
              marginLeft={isMobile ? 10 : 0}
              maxWidth={550}
            >
              <AutoDiv
                minWidth={!isMobile ? 400 : "100%"}
                position={"relative"}
              >
                <StyledCardMedium>
                  <IconButton
                    style={{
                      color: "black",
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                    onClick={() => setActiveCard(undefined)}
                  >
                    <Close />
                  </IconButton>
                  <AutoDiv
                    paddingTop={25}
                    alignItems={"center"}
                    display={"flex"}
                    flexDirection={"column"}
                    width={"100%"}
                  >
                    <AutoDiv
                      display={"grid"}
                      gridAutoRows={
                        isMobile
                          ? sizes.travelCardImage[
                              TRAVEL_CARD_VARIANTS.EXTRA_LARGE
                            ]
                          : sizes.travelCardImage[TRAVEL_CARD_VARIANTS.MEDIUM]
                      }
                      columnGap={15}
                      gridTemplateColumns={
                        isMobile
                          ? sizes.travelCardImage[
                              TRAVEL_CARD_VARIANTS.EXTRA_LARGE
                            ]
                          : `${
                              sizes.travelCardImage[TRAVEL_CARD_VARIANTS.MEDIUM]
                            }px ${
                              sizes.travelCardImage[TRAVEL_CARD_VARIANTS.MEDIUM]
                            }px`
                      }
                      gap={10}
                      width={isMobile ? 350 : 515}
                      height={isMobile ? 400 : 600}
                      overflowY={"scroll"}
                    >
                      {publicTravelsMap[activeCard].map(
                        ({ content, src = "", travelId }) => (
                          <ImageGridCard
                            isMobile={isMobile || false}
                            key={travelId}
                            travelId={travelId}
                            src={src}
                            content={content}
                          />
                        )
                      )}
                    </AutoDiv>
                  </AutoDiv>
                </StyledCardMedium>
              </AutoDiv>
            </AutoDiv>
          )}
        </FlexRow>
      )}
    </AutoDiv>
  );
};
