import { Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = any;

type Params = { campaignId: string };

type Res = void;

export type SendCampaignEndpoint = Endpoint<ReqBody, Params, Res>;

export const sendCampaignEndpoint = {
  protocol: PROTOCOL.POST,
  url: ({ campaignId }: Params) => `/campaigns/${campaignId}/send`,
};
