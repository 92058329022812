import { AccountDetails, FlexColumn, FlexRow } from "components";
import { useUserInfoForm } from "content";
import { sizes } from "config";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { authMountain } from "@ttp/goods-config-client";
import { useSelector } from "react-redux";

export const AccountDetailsPage = () => {
  const userInfoForm = useUserInfoForm();
  const { fanDoc } = useSelector(authMountain.selectors.get);

  const { handleSave, hasChanged } = userInfoForm;
  const handleSubmit = () => {
    if (!fanDoc) return;

    handleSave();
  };

  return (
    <FlexColumn alignItems={"center"} paddingTop={20}>
      <FlexColumn maxWidth={sizes.uploadPreview} gap={10}>
        <Typography variant={"h3"}>Account Details</Typography>
        <AccountDetails userInfoForm={userInfoForm} onSave={handleSubmit} />
        <FlexRow justifyContent={"flex-end"}>
          <Button disabled={!hasChanged} onClick={handleSubmit}>
            Save
          </Button>
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  );
};
