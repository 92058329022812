import {
  AccountDetails,
  AutoDiv,
  ConfirmUpload,
  FlexColumn,
  FlexRow,
  ImageCaption,
  ImageCrop,
  ImageLocation,
  ImageUpload,
  SignIn,
} from "components";
import Button from "@mui/material/Button";
import { dependencies, useUploadForm, useUserInfoForm } from "content";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { authMountain, clientMountain } from "@ttp/goods-config-client";
import { useSelector } from "react-redux";
import { SUBSCRIPTION_STATUS } from "@ttp/common";
import { CircularProgress, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { sizes } from "config";
import ReactConfetti from "react-confetti";

const StyledPaper = styled(Paper)({
  height: 50,
  width: sizes.uploadPreview,
  padding: 10,
  zIndex: 500,
  backgroundColor: "#007dc3",
  color: "#fff",
});
export const UploadPage = () => {
  const { get } = authMountain.selectors;
  const { user = {}, fanDoc, userDoc } = useSelector(get);
  const uploadForm = useUploadForm();
  const userInfoForm = useUserInfoForm(true);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const {
    formState,
    formData,
    updateFormState,
    updateFormData,
    handleSave,
    resetFormToInitialData,
    getValidatedFormData,
  } = uploadForm;
  const { uploadFileBase64, croppedUploadBase64 } = formData;

  const { step, isUploading } = formState;

  const onSave = () => {
    handleNext();
  };

  const canPost =
    fanDoc?.firstName &&
    fanDoc?.lastName &&
    userDoc?.agreedToTOS &&
    fanDoc?.subscriptionStatus === SUBSCRIPTION_STATUS.CONSENTED;

  useEffect(() => {
    clientMountain.actions.update(dependencies)({
      isUploadSequence: step !== 0,
    });

    window.scrollTo(0, 1);
    return () => {
      clientMountain.actions.update(dependencies)({
        isUploadSequence: false,
      });
    };
  }, [step]);
  const formConfig = [
    {
      onReturnHere: () => {
        updateFormState({ uploadFile: undefined });
        updateFormData({ uploadFileBase64: "", croppedUploadBase64: "" });
      },
      helperText: "Upload a photo in the field below.",
      component: <ImageUpload uploadForm={uploadForm} />,
      shouldHideNext: true,
    },
    {
      helperText: "Crop your photo, then hit save.",
      component: <ImageCrop uploadForm={uploadForm} />,
      onLeaveHere: () => {
        if (!croppedUploadBase64.value)
          updateFormData({ croppedUploadBase64: uploadFileBase64.value });
      },
    },
    {
      helperText: "Caption your photo below.",
      component: <ImageCaption uploadForm={uploadForm} />,
    },
    {
      helperText: "Use the field below to add a pin to the map.",
      component: <ImageLocation uploadForm={uploadForm} />,
      nextAmount: user.isAnonymous ? 1 : canPost ? 3 : 2,
      beforeNext: async () => {
        await uploadForm.formState.setLocationSearch(uploadForm);

        const validDate = uploadForm.getValidatedFormData();

        if (!validDate) return new Error();

        if (uploadForm.formData.location.isError) return new Error();
      },
    },
    {
      helperText: "One more step! Sign in or create an account.",
      component: <SignIn onSignIn={() => handleNext()} />,
      shouldHideNext: true,
      nextAmount: canPost ? 2 : 1,
    },
    {
      helperText: "Complete your account details below.",
      component: <AccountDetails userInfoForm={userInfoForm} onSave={onSave} />,
      beforeNext: async () => {
        try {
          const validData = userInfoForm.getValidatedFormData();
          if (!validData) return new Error("Invalid data ");

          await userInfoForm.handleSave();
        } catch (e) {
          return new Error("Unable to save form");
        }
      },
    },
    {
      backAmount: user.isAnonymous ? 1 : 3,
      helperText: "Everything look ok?",
      component: <ConfirmUpload uploadForm={uploadForm} />,
    },
  ];

  const handleBack = () => {
    if (step === 0) return;
    const { backAmount = 1 } = formConfig[step];
    const previousStep = step - backAmount;
    const { onReturnHere } = formConfig[previousStep];
    if (onReturnHere) onReturnHere();
    updateFormState({ step: previousStep });
  };

  const handleNext = async () => {
    if (step === formConfig.length - 1) {
      const validData = getValidatedFormData();

      if (!validData) return;
      updateFormState({ isUploading: true });
      handleSave()
        .catch((error) => {
          console.error("Couldn't upload travel", error);
        })
        .then(() => {
          resetFormToInitialData();
          updateFormState({ step: 0, uploadFile: null });
          setIsUploadModalOpen(true);
        })
        .finally(() => {
          updateFormState({ isUploading: false });
        });
      return;
    }
    const { onLeaveHere, nextAmount = 1, beforeNext } = formConfig[step];

    if (beforeNext) {
      const error = await beforeNext();
      if (error && error instanceof Error) return;
    }

    if (onLeaveHere) onLeaveHere();
    const nextStep = step + nextAmount;

    updateFormState({ step: nextStep });
  };

  useEffect(() => {
    const fieldToFocus = document.getElementById("upload-field-to-focus");

    if (!fieldToFocus) return;

    fieldToFocus.focus();
  }, [step]);

  const { isMobile, windowWidth, windowHeight } = useSelector(
    clientMountain.selectors.get
  );

  return (
    <>
      <FlexColumn
        width={"100%"}
        height={"100vh"}
        padding={"10px 50px 100px 50px "}
        justifyContent={"flex-start"}
        alignItems={"center"}
        overflowY={"hidden"}
      >
        <StyledPaper elevation={6}>
          <AutoDiv>
            <Typography style={{ fontSize: 14 }}>
              {isUploading
                ? "Submitting your Trash Panda Travel for review..."
                : formConfig[step].helperText}
            </Typography>
          </AutoDiv>
        </StyledPaper>
        <FlexRow justifyContent={"center"}>
          {isUploading ? (
            <FlexColumn minHeight={"50vh"} justifyContent={"center"}>
              <CircularProgress />
            </FlexColumn>
          ) : (
            formConfig[step].component
          )}
        </FlexRow>
      </FlexColumn>
      <FlexRow
        justifyContent={"flex-end"}
        position={"fixed"}
        bottom={isMobile ? 10 : 15}
        zIndex={5000}
        pointerEvents={"none"}
      >
        <FlexRow
          gap={15}
          justifyContent={"flex-end"}
          paddingRight={isMobile ? 15 : 30}
          maxWidth={"400"}
        >
          {step !== 0 && (
            <Button
              color={"secondary"}
              variant={"outlined"}
              onClick={handleBack}
              style={{ pointerEvents: "all" }}
            >
              Go Back
            </Button>
          )}
          {formConfig[step] && !formConfig[step].shouldHideNext && (
            <Button
              color={"secondary"}
              variant={"contained"}
              onClick={handleNext}
              style={{ pointerEvents: "all" }}
            >
              {step === formConfig.length - 1 ? "Upload" : "Next"}
            </Button>
          )}
        </FlexRow>
      </FlexRow>

      <Modal open={isUploadModalOpen}>
        <div style={{ height: "100%" }}>
          <ReactConfetti
            width={windowWidth}
            height={windowHeight}
            initialVelocityX={10}
            initialVelocityY={10}
          />
          <FlexColumn justifyContent={"center"} alignItems={"center"}>
            <Card
              sx={{
                width: 350,
                height: 200,
                padding: "10px 20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant={"body1"}>
                You have successfully uploaded to the Trash Panda Travels
                project. You will be notified via text when your submission is
                approved and published.
              </Typography>
              <FlexRow justifyContent={"center"}>
                <Button
                  color={"primary"}
                  onClick={() => setIsUploadModalOpen(false)}
                >
                  Ok
                </Button>
              </FlexRow>
            </Card>
          </FlexColumn>
        </div>
      </Modal>
    </>
  );
};
