import { Endpoint, GetTravelResponse } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = any;

type Params = { travelId: string };

type Res = GetTravelResponse;

export type GetTravelEndpoint = Endpoint<ReqBody, Params, Res>;

export const getTravelEndpoint = {
  protocol: PROTOCOL.GET,
  url: ({ travelId }: Params) => `/travel/${travelId}`,
};
