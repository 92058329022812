import { ACTIONS } from "@ttp/goods/keys";
import { Dependencies, ReduxStoreIntegration } from "@ttp/goods";

export const createGoodsReducer = (
  dependencies: Dependencies & ReduxStoreIntegration
) => {
  const {
    Error,
    reduxToolkit: { createAction, createReducer },
  } = dependencies;

  return <State>(path: string, initialState: State) => {
    try {
      const update = createAction<Partial<State>>(`${path}/${ACTIONS.UPDATE}`);

      return createReducer(initialState, (builder) => {
        builder.addCase(update, (state, action) => {
          const { payload } = action;

          for (const key in payload) {
            state[key] = payload[key];
          }
        });
      });
    } catch (e) {
      return new Error("Something unexpected happened in createGoodsReducer");
    }
  };
};
