import * as auth from "firebase/auth";
import { authMountain } from "@ttp/goods-config-client";
import { env } from "config";
import { firebaseRocket } from "loaders/firebaseRocket";
import axios from "axios";

const { addFirebaseAuthToken } = authMountain.operations;

const axiosLaunch = axios.create({
  baseURL: env.REACT_APP_AXIOS_LAUNCH_URL,
});
const { app } = firebaseRocket;
axiosLaunch.interceptors.request.use(addFirebaseAuthToken({ app, auth }));

export { axiosLaunch };
