import React, { useEffect, useState } from "react";
import "./app.css";
//components
import {
  authMountain,
  clientMountain,
  travelMountain,
} from "@ttp/goods-config-client";
import { dependencies, router } from "content";
import { Provider } from "react-redux";
import { reduxToyota } from "loaders";
import { RouterProvider } from "react-router-dom";
import { ImageContextProvider } from "context";

function App() {
  const {
    actions: { mountReducer },
    operations: { connectClientAuthInstance },
  } = authMountain;

  const {
    actions: { mountReducer: mountTravelReducer },
  } = travelMountain;

  const {
    actions: { mountReducer: mountClientReducer },
  } = clientMountain;

  const [hasMountedReducers, setHasMountedReducers] = useState(false);
  useEffect(() => {
    if (hasMountedReducers) return;
    let latestHasMounted;
    setHasMountedReducers((prev) => {
      latestHasMounted = prev;
      return prev;
    });
    if (latestHasMounted) return;
    setHasMountedReducers(true);
    mountClientReducer(dependencies);
    mountReducer(dependencies);
    mountTravelReducer(dependencies);
    return connectClientAuthInstance(dependencies)(["admin"]);
  }, []);

  return (
    <div className="App">
      <Provider store={reduxToyota.store}>
        <ImageContextProvider>
          <RouterProvider router={router()} />
        </ImageContextProvider>
      </Provider>
    </div>
  );
}

export default App;
