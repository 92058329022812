import { useEffect } from "react";
import { dependencies } from "content";
import { clientMountain } from "@ttp/goods-config-client";
import { useStore } from "react-redux";
import MobileDetect from "mobile-detect";

export const ResizeHandler = () => {
  const store = useStore();
  const {
    actions: { update },
    selectors: { get },
  } = clientMountain;
  const md = new MobileDetect(window.navigator.userAgent);

  useEffect(() => {
    const resizeListener = () => {
      const { isMobile: currentState } = get(store);
      const isMobile = md.mobile() !== null || window.innerWidth < 850;

      if (currentState === isMobile) return;

      update(dependencies)({
        isMobile,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      });
    };

    window.addEventListener("resize", resizeListener);
    resizeListener();
    setTimeout(resizeListener, 500);
    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  return <></>;
};
