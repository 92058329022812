import { Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = {
  Body: string;
  From: string;
  To: string;
  [key: string]: any;
};

type Params = any;

type Res = void;

export type PostTextComesInTwilioEndpoint = Endpoint<ReqBody, Params, Res>;

export const postTextComesInTwilioEndpoint = {
  protocol: PROTOCOL.POST,
  url: () => `/twilio/textComesIn`,
};
