import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import ExploreIcon from "@mui/icons-material/Explore";
import HomeIcon from "@mui/icons-material/Home";
import { clientMountain } from "@ttp/goods-config-client";
import { dependencies } from "content";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_KEYS } from "keys";
import Paper from "@mui/material/Paper";
import { ReactComponent as LogoWide } from "content/images/logo-wide.svg";
import { AutoDiv } from "components/02-layout";

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 5000,
  top: -30,
  // left: 0,
  right: 30,
  height: 75,
  width: 75,
  margin: "0 auto",
});
const StyledIconPaper = styled(Paper)({
  position: "absolute",
  zIndex: 5000,
  top: -30,
  // left: 0,
  right: 160,
  margin: "0 auto",
  width: 120,
  height: 75,
  borderRadius: 25,
});

export default function BottomAppBar() {
  const {
    actions: { update },
    selectors: { get },
  } = clientMountain;
  const setIsDrawerOpen = (isDrawerOpen: boolean) => {
    update(dependencies)({ isDrawerOpen });
  };

  const navigate = useNavigate();
  const location = useLocation();

  const { isMobile, isDrawerOpen, windowWidth, isUploadSequence } =
    useSelector(get);

  if (isUploadSequence)
    return (
      <React.Fragment>
        <AppBar
          position="fixed"
          color="primary"
          sx={{
            top: "auto",
            bottom: 0,
            left: 0,
            width: "100vw",
            zIndex: 950,
          }}
        >
          <Toolbar>
            <AutoDiv display={"flex"} justifyContent={"flex-start"}>
              <StyledIconPaper
                elevation={3}
                sx={{
                  left: isMobile ? 15 : 30,
                  marginLeft: 0,
                  height: 60,
                  top: -15,
                }}
              >
                <AutoDiv justifyContent={"center"} display={"flex"}>
                  <LogoWide width={100} height={60} />
                </AutoDiv>
              </StyledIconPaper>
            </AutoDiv>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: "auto",
          bottom: 0,
          left: isMobile ? 0 : 250,
          width: isMobile ? undefined : "calc(100% - 250px)",
          zIndex: 950,
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              color="inherit"
              aria-label="open drawer"
            >
              <MenuIcon />
            </IconButton>
          )}

          {/*<IconButton color="inherit" onClick={() => navigate(ROUTE_KEYS.ROOT)}>*/}
          {/*  <HomeIcon />*/}
          {/*</IconButton>*/}
          <IconButton color="inherit" onClick={() => navigate(ROUTE_KEYS.ROOT)}>
            <ExploreIcon />
          </IconButton>

          <StyledIconPaper
            elevation={3}
            sx={{ right: windowWidth / 2 - 60 - (isMobile ? 0 : 250 / 2) }}
          >
            <AutoDiv justifyContent={"center"} display={"flex"}>
              <LogoWide width={100} height={75} />
            </AutoDiv>
          </StyledIconPaper>

          {location.pathname !== ROUTE_KEYS.UPLOAD && (
            <StyledFab
              color="secondary"
              aria-label="add"
              onClick={() => navigate(ROUTE_KEYS.UPLOAD)}
            >
              <AddIcon />
            </StyledFab>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
