import { Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = any;

type Params = any;

type Res = { fromPhone: string; bodyText: string };

export type GetApprovedPromoEndpoint = Endpoint<ReqBody, Params, Res>;

export const getApprovedPromoEndpoint = {
  protocol: PROTOCOL.GET,
  url: () => `/approved-promo-config`,
};
