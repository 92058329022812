export enum ADMIN_SIDER_MENUS {
  ADMINS = "ADMINS",
  ADMIN_HOME = "ADMIN_HOME",
  ANALYTICS = "ANALYTICS",
  CAMPAIGNS = "CAMPAIGNS",
  CAMPAIGNS_DRAFT = "CAMPAIGNS_DRAFT",
  CAMPAIGNS_EDIT = "CAMPAIGNS_EDIT",
  CAMPAIGNS_HOME = "CAMPAIGNS_HOME",
  CAMPAIGNS_NEW = "CAMPAIGNS_NEW",
  CAMPAIGNS_SENT = "CAMPAIGNS_SENT",
  FANS = "FANS",
  FANS_HOME = "FANS_HOME",
  FANS_UPLOAD = "FANS_UPLOAD",
  MODERATION = "MODERATION",
  SEGMENTS = "SEGMENTS",
  SEGMENTS_EDIT = "SEGMENTS_EDIT",
  SEGMENTS_HOME = "SEGMENTS_HOME",
  SEGMENTS_NEW = "SEGMENTS_NEW",
  TAGS = "TAGS",
  TRAVELS = "TRAVELS",
  TRAVELS_HOME = "TRAVELS_HOME",
  USERS = "USERS",
}
