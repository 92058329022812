import { sizes } from "config";
import { UploadFormUtil } from "content";
import { FlexColumn, FlexRow } from "components";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from "react-image-crop";
import { useCallback, useRef, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { CheckRounded, RemoveCircle } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const StyledPaper = styled(Paper)({
  width: sizes.uploadPreview,
  height: sizes.uploadPreview,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
});

export const ImageCrop = ({ uploadForm }: { uploadForm: UploadFormUtil }) => {
  const [crop, setCrop] = useState<Crop>();

  const { updateFormData, formData } = uploadForm;
  const { uploadFileBase64, croppedUploadBase64 } = formData;

  const imgRef = useRef<HTMLImageElement | null>(null);

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) => {
    const smallerSide = Math.min(mediaWidth, mediaHeight);
    const longerSide = Math.max(mediaWidth, mediaHeight);

    const sizePct = (smallerSide / longerSide) * 100;

    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: sizePct,
          height: sizePct,
        },
        aspect,
        sizes.uploadPreview,
        sizes.uploadPreview
      ),
      sizes.uploadPreview,
      sizes.uploadPreview
    );
  };

  const handleImageLoad = async () => {
    const image = new Image();

    await new Promise((resolve) => {
      image.onload = resolve;
      image.src = croppedUploadBase64.value || uploadFileBase64.value;
    });

    const { height: sourceHeight, width: sourceWidth } = image;
    if (sourceHeight === sourceWidth) return;

    setCrop(centerAspectCrop(sourceWidth, sourceHeight, 1));
  };

  const handleSaveCrop = useCallback(async () => {
    if (!imgRef.current || !crop) return;

    const { x, width, height, y, unit } = crop;

    let stableX = x,
      stableY = y,
      stableWidth = width,
      stableHeight = height;

    if (unit === "%") {
      stableX = (x / 100) * sizes.uploadPreview;
      stableY = (y / 100) * sizes.uploadPreview;
      stableWidth = (width / 100) * sizes.uploadPreview;
      stableHeight = (height / 100) * sizes.uploadPreview;
    }

    const image = new Image();

    await new Promise((resolve) => {
      image.onload = resolve;
      image.src = croppedUploadBase64.value || uploadFileBase64.value;
    });

    const { height: sourceHeight, width: sourceWidth } = image;
    const longerSide = Math.max(sourceHeight, sourceWidth);
    const horizontalPadding =
      longerSide - sourceWidth ? (longerSide - sourceWidth) / 2 : 0;
    const verticalPadding =
      longerSide - sourceHeight ? (longerSide - sourceHeight) / 2 : 0;

    const sx = (stableX / sizes.uploadPreview) * longerSide - horizontalPadding,
      sy = (stableY / sizes.uploadPreview) * longerSide - verticalPadding,
      sw = (stableWidth / sizes.uploadPreview) * longerSide,
      sh = (stableHeight / sizes.uploadPreview) * longerSide,
      dw = (stableWidth / sizes.uploadPreview) * longerSide,
      dh = (stableHeight / sizes.uploadPreview) * longerSide;

    const canvas = document.createElement("canvas");
    canvas.height = dh;
    canvas.width = dw;
    const context = canvas.getContext("2d");

    if (!context) return;

    context.drawImage(image, sx, sy, sw, sh, 0, 0, dw, dh);
    updateFormData({ croppedUploadBase64: canvas.toDataURL() });
    setCrop(undefined);
  }, [crop]);

  const handleClearCrop = () => {
    updateFormData({ croppedUploadBase64: "" });
    setCrop(undefined);
  };

  return (
    <FlexColumn
      width={"100%"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      gap={20}
      paddingTop={20}
    >
      <StyledPaper elevation={6}>
        <ReactCrop aspect={1} crop={crop} onChange={(c) => setCrop(c)}>
          <img
            width={350}
            height={350}
            alt={"Your cropped image"}
            src={croppedUploadBase64.value || uploadFileBase64.value}
            onLoad={handleImageLoad}
            style={{ objectFit: "contain" }}
            ref={imgRef}
          />
        </ReactCrop>
      </StyledPaper>

      <FlexRow
        maxWidth={600}
        justifyContent={"center"}
        alignItems={"center"}
        gap={20}
      >
        <Typography>Crop Controls:</Typography>
        <Button startIcon={<RemoveCircle />} onClick={handleClearCrop}>
          Clear
        </Button>
        <Button startIcon={<CheckRounded />} onClick={handleSaveCrop}>
          Save
        </Button>
      </FlexRow>
    </FlexColumn>
  );
};
