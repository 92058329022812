import { ANALYTIC_USER_EVENTS } from "@ttp/common/keys";

export const analyticsUserEventsConditionals = {
  [ANALYTIC_USER_EVENTS.TRAVEL_UPLOADED]: {
    addFields: ["travelId"],
  },
  [ANALYTIC_USER_EVENTS.TRAVEL_MODERATED_APPROVED]: {
    addFields: ["travelId", "approvedBy"],
  },
} as const;
