import {
  GetUserAnalyticsEndpoint,
  getUserAnalyticsEndpoint,
  PostUserAnalyticsEndpoint,
  postUserAnalyticsEndpoint,
} from "@ttp/common";
import {
  createAxiosLaunchGetRequest as newGet,
  createAxiosLaunchRequest as newReq,
} from "@ttp/goods-config-client/utils/operationCreators";

export const analyticsMountain = {
  operations: {
    getUserAnalytics: newGet<GetUserAnalyticsEndpoint>(
      getUserAnalyticsEndpoint
    ),
    postUserAnalytics: newReq<PostUserAnalyticsEndpoint>(
      postUserAnalyticsEndpoint
    ),
  },
};
