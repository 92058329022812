import { useFirebaseUI } from "utils/auth";
import { firebaseCompat } from "loaders";
import { AutoDiv, FlexColumn } from "components";
import Typography from "@mui/material/Typography";
import { sizes } from "config";
import { authMountain, clientMountain } from "@ttp/goods-config-client";
import { useSelector } from "react-redux";
import { CircularProgress, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";

const StyledSkeleton = styled(Skeleton)({
  transform: "scale(1,1)",
  width: sizes.uploadPreview,
  height: 375,
});

export const SignIn = ({ onSignIn }: { onSignIn: () => any }) => {
  const { app } = firebaseCompat;
  const { Component } = useFirebaseUI(app.auth());
  const { get } = authMountain.selectors;
  const { get: clientGet } = clientMountain.selectors;
  const { isMobile } = useSelector(clientGet);
  const { user = {}, isAuthLoaded, fanDoc, userDoc } = useSelector(get);

  useEffect(() => {
    if (!isAuthLoaded) return;
    if (user.isAnonymous === false && fanDoc && userDoc) {
      if (onSignIn) onSignIn();
    }
  }, [user.isAnonymous, fanDoc, userDoc]);

  return (
    <FlexColumn gap={20} paddingTop={20} alignItems={"center"}>
      <AutoDiv minHeight={375}>
        {!isAuthLoaded ? (
          <StyledSkeleton />
        ) : !user.phoneNumber ? (
          <Component />
        ) : (
          <CircularProgress />
        )}
      </AutoDiv>
      <AutoDiv maxWidth={sizes.uploadPreview}>
        <Typography
          variant={"h5"}
          style={{ fontSize: 16, transform: "translate(0,-5px)" }}
        >
          Why are we asking you to sign in?
        </Typography>
        <Typography variant={"body2"} style={{ fontSize: 12 }}>
          We want to keep our fans safe. Any content uploaded to this platform
          will be linked to your phone number and display name. You'll receive a
          text from us when your upload is approved.
        </Typography>
      </AutoDiv>
    </FlexColumn>
  );
};
