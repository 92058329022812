import { Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";

type ReqBody = any;

type Params = { adminId: string };

type Res = void;

export type DeleteAdminEndpoint = Endpoint<ReqBody, Params, Res>;

export const deleteAdminEndpoint = {
  protocol: PROTOCOL.DELETE,
  url: ({ adminId }: Params) => `/admin/${adminId}`,
};
