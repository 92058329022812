import { EditSegmentUpdates, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = EditSegmentUpdates;

type Params = { segmentId: string };

type Res = void;

export type PatchSegmentEndpoint = Endpoint<ReqBody, Params, Res>;

export const patchSegmentEndpoint = {
  protocol: PROTOCOL.PATCH,
  url: ({ segmentId }: Params) => `/segments/${segmentId}`,
};
