/**
 *  This is a firebase instance nicknamed Rocket
 *
 *  Naming the firebase instance to allow for multiple firebase instances in the future
 *
 */
import { env } from "config";

import { connectAuthEmulator, getAuth, signInAnonymously } from "firebase/auth";
import { connectDatabaseEmulator, getDatabase } from "firebase/database";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

import { FirebaseUtil } from "types/FirebaseUtil";
import { initializeApp } from "firebase/app";

import "firebase/compat/auth";
import compatApp from "firebase/compat/app";
import { dependencies } from "content";
import { reduxToyota } from "loaders/reduxToyota";
import { authMountain } from "@ttp/goods-config-client";

const firebaseConfig = {
  apiKey: env.REACT_APP_ROCKET_API_KEY,
  appId: env.REACT_APP_ROCKET_APP_ID,
  authDomain: env.REACT_APP_ROCKET_AUTH_DOMAIN,
  messagingSenderId: env.REACT_APP_ROCKET_MESSAGING_SENDER_ID,
  projectId: env.REACT_APP_ROCKET_PROJECT_ID,
  storageBucket: env.REACT_APP_ROCKET_STORAGE_BUCKET,
  measurementId: env.REACT_APP_ROCKET_MEASUREMENT_ID,
};

compatApp.initializeApp(firebaseConfig);
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebaseRocket = {
  app,
  auth: getAuth(app),
  database: getDatabase(app),
  firestore: getFirestore(app),
  storage: getStorage(app),
  analytics: getAnalytics(app),
} as FirebaseUtil;

export const firebaseCompat = { app: compatApp };

// establish auth listener
firebaseRocket.auth.onAuthStateChanged((user) => {
  if (!user) {
    authMountain.actions
      .removeReducer(dependencies)
      .then(() => authMountain.actions.mountReducer(dependencies));

    signInAnonymously(firebaseRocket.auth)
      .then(() => {})
      .catch(() => {
        console.error(
          "Something unexpected happened in signInAnonymously callback"
        );
      });
    return;
  }

  const { isAnonymous } = user;

  if (isAnonymous) return;

  const { fanDoc, userDoc } = authMountain.selectors.get(
    reduxToyota.store.getState()
  );

  if (!fanDoc || !userDoc) {
    authMountain.operations
      .connectUser(dependencies)()
      .catch((error) => console.error("Couldn't connect user", error))
      .then((response) => {
        if (!response) return;
        if (response.data) {
          const { user, fan } = response.data;
          authMountain.actions.update(dependencies)({
            userDoc: user,
            fanDoc: fan,
          });
        }
      });
  }
});

// Initialize Emulators
const {
  REACT_APP_FIREBASE_ROCKET_AUTH_EMULATOR_HOST,
  REACT_APP_FIREBASE_ROCKET_FIRESTORE_EMULATOR_HOST,
  REACT_APP_FIREBASE_ROCKET_DATABASE_EMULATOR_HOST,
  REACT_APP_FIREBASE_ROCKET_STORAGE_EMULATOR_HOST,
} = env;

if (REACT_APP_FIREBASE_ROCKET_AUTH_EMULATOR_HOST) {
  console.log("Connecting to firebase rocket emulator - Auth");
  connectAuthEmulator(
    firebaseRocket.auth,
    REACT_APP_FIREBASE_ROCKET_AUTH_EMULATOR_HOST,
    { disableWarnings: true }
  );
}

if (REACT_APP_FIREBASE_ROCKET_DATABASE_EMULATOR_HOST) {
  console.log("Connecting to firebase rocket emulator - Database");
  connectDatabaseEmulator(
    firebaseRocket.database,
    REACT_APP_FIREBASE_ROCKET_DATABASE_EMULATOR_HOST.split(":")[0],
    parseInt(REACT_APP_FIREBASE_ROCKET_DATABASE_EMULATOR_HOST.split(":")[1])
  );
}
if (REACT_APP_FIREBASE_ROCKET_FIRESTORE_EMULATOR_HOST) {
  console.log("Connecting to firebase rocket emulator - Firestore");
  connectFirestoreEmulator(
    firebaseRocket.firestore,
    REACT_APP_FIREBASE_ROCKET_FIRESTORE_EMULATOR_HOST.split(":")[0],
    parseInt(REACT_APP_FIREBASE_ROCKET_FIRESTORE_EMULATOR_HOST.split(":")[1])
  );
}

if (REACT_APP_FIREBASE_ROCKET_STORAGE_EMULATOR_HOST) {
  console.log("Connecting to firebase rocket emulator - Storage");
  connectStorageEmulator(
    firebaseRocket.storage,
    REACT_APP_FIREBASE_ROCKET_STORAGE_EMULATOR_HOST.split(":")[0],
    parseInt(REACT_APP_FIREBASE_ROCKET_STORAGE_EMULATOR_HOST.split(":")[1])
  );
}
