import { UploadFormUtil } from "content";

export const handleSearchLocation = async (
  uploadForm: UploadFormUtil,
  mapInstance: google.maps.Map,
  placesService: google.maps.places.PlacesService
) => {
  const { formData, updateFormData } = uploadForm;
  const { location } = formData;

  placesService.findPlaceFromQuery(
    {
      query: location.value,
      fields: ["formatted_address", "place_id", "geometry", "name", "type"],
    },
    async (results, status) => {
      if (
        results &&
        results[0] &&
        status === google.maps.places.PlacesServiceStatus.OK
      ) {
        const { place_id, geometry, types, name } = results[0];
        const latLng = geometry?.location;

        if (!latLng || !place_id) return;
        updateFormData({ geoData: latLng.toJSON() });
        mapInstance.setCenter(latLng);
        await placesService.getDetails(
          { placeId: place_id, fields: ["address_component"] },
          (results) => {
            if (!results) return;
            const { address_components } = results;
            if (!address_components) return;
            let neighborhood, locality, city, state, country, isIntl;

            address_components.forEach(({ types, short_name, long_name }) => {
              if (types.includes("locality")) {
                locality = long_name;
                return;
              }

              if (types.includes("country")) {
                if (short_name !== "US") isIntl = true;
                country = short_name;
                return;
              }

              if (types.includes("neighborhood")) {
                neighborhood = long_name;
                return;
              }

              if (types.includes("administrative_area_level_1")) {
                state = short_name;
                return;
              }

              if (types.includes("sublocality_level_1")) {
                city = long_name;
                return;
              }
            });

            let formattedLocation = location.value;

            if (city && state) {
              formattedLocation = `${city}, ${isIntl ? country : state}`;
            }

            if (locality && state) {
              formattedLocation = `${locality}, ${isIntl ? country : state}`;
            }

            if (neighborhood && city) {
              formattedLocation = `${neighborhood}, ${city}`;
            }

            if (neighborhood && locality) {
              formattedLocation = `${neighborhood}, ${locality}`;
            }

            if (name && types) {
              if (types.includes("point_of_interest")) {
                formattedLocation = `${name}, ${isIntl ? country : state}`;
              } else if (types.includes("establishment")) {
                formattedLocation = `${name}, ${isIntl ? country : state}`;
              }
            }

            updateFormData({
              location: formattedLocation,
            });
          }
        );
      }
    }
  );

  return;
};
