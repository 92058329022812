import { EditFanUpdates, Endpoint } from "@ttp/common/types";
import { PROTOCOL } from "@ttp/common/keys";
type ReqBody = { fans: EditFanUpdates[] };

type Params = any;

type Res = string[];

export type PostFansEndpoint = Endpoint<ReqBody, Params, Res>;

export const postFansEndpoint = {
  protocol: PROTOCOL.POST,
  url: () => `/fans`,
};
