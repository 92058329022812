import { ClientDependencies } from "@ttp/goods-config-client/types";
import { TRAVEL_STATUS } from "@ttp/common";

export const getTravels = (
  dependencies: Pick<ClientDependencies, "firestore" | "app" | "logger">
) => {
  const {
    firestore: { collection, query, where, Firestore, getDocs },
    logger,
  } = dependencies;

  return async () => {
    try {
      const travelsRef = collection(Firestore, "travels");
      const travelsQuery = query(
        travelsRef,
        where("status", "==", TRAVEL_STATUS.PUBLIC)
      );
      const travelsQuerySnap = await getDocs(travelsQuery);

      return travelsQuerySnap.docs.map((doc) => {
        return doc.data();
      });
    } catch (e) {
      logger.error(e);
      return new Error("Something unexpected happened in getTravels");
    }
  };
};
