import {
  FanDoc,
  PatchUserEndpoint,
  SUBSCRIPTION_STATUS,
  UserDoc,
} from "@ttp/common";
import { authMountain } from "@ttp/goods-config-client";
import { useSelector } from "react-redux";
import { FormUtilType, useForm3 } from "utils/ui";
import { useEffect } from "react";
import { dependencies } from "content";

type UserInfoFormData = Required<
  Pick<FanDoc, "subscriptionStatus" | "firstName" | "lastName"> &
    Pick<UserDoc, "agreedToTOS">
>;

type UserInfoFormState = any;

export type UserInfoFormUtil = FormUtilType<
  UserInfoFormData,
  UserInfoFormState
>;

export const useUserInfoForm = (isUploadForm = false) => {
  const { isAuthLoaded, fanDoc, userDoc } = useSelector(
    authMountain.selectors.get
  );

  const {
    subscriptionStatus = SUBSCRIPTION_STATUS.UNKNOWN,
    lastName = "",
    firstName = "",
  } = fanDoc || {};

  const { agreedToTOS = false } = userDoc || {};

  const userInfoForm = useForm3<UserInfoFormData, UserInfoFormState>(
    { subscriptionStatus, lastName, firstName, agreedToTOS },
    { isUploadForm }
  );

  const {
    resetForm,
    getValidatedFormDataUpdates,
    hasChanged,
    setValidationConfig,
  } = userInfoForm;

  useEffect(() => {
    setValidationConfig({
      agreedToTOS: (fieldValue) => {
        if (!fieldValue)
          return {
            isError: true,
            validationText:
              "You must agree to terms of service to use this platform.",
          };
      },
      subscriptionStatus: (fieldValue, validationHelpers) => {
        const { isUploadForm } = validationHelpers.newState;
        if (!isUploadForm) return;

        if (!fieldValue)
          return {
            isError: true,
            validationText: "Consenting to receive texts is required",
          };
        if (fieldValue !== SUBSCRIPTION_STATUS.CONSENTED)
          return {
            isError: true,
            validationText: "Consenting to receive texts is required",
          };
      },
      firstName: (fieldValue) => {
        if (!fieldValue)
          return { isError: true, validationText: "This is a required field." };
      },
      lastName: (fieldValue) => {
        if (!fieldValue)
          return { isError: true, validationText: "This is a required field." };
      },
    });
  }, []);

  useEffect(() => {
    if (!isAuthLoaded) return;
    resetForm({ subscriptionStatus, lastName, firstName, agreedToTOS });
  }, [fanDoc, userDoc]);

  const handleSave = async () => {
    if (!hasChanged) return;
    const formUpdates = getValidatedFormDataUpdates();
    if (!formUpdates) return;

    const userDocUpdates = {} as PatchUserEndpoint["reqBody"];
    if (formUpdates.subscriptionStatus !== undefined)
      userDocUpdates.subscriptionStatus = formUpdates.subscriptionStatus;
    if (formUpdates.agreedToTOS !== undefined)
      userDocUpdates.agreedToTOS = formUpdates.agreedToTOS;
    if (formUpdates.lastName !== undefined)
      userDocUpdates.lastName = formUpdates.lastName;
    if (formUpdates.firstName !== undefined)
      userDocUpdates.firstName = formUpdates.firstName;

    await authMountain.operations.patchUser(dependencies)({}, userDocUpdates);

    authMountain.operations
      .connectUser(dependencies)()
      .catch((error) => console.error("Couldn't connect user", error))
      .then((response) => {
        if (!response) return;
        if (response.data) {
          const { user, fan } = response.data;
          authMountain.actions.update(dependencies)({
            userDoc: user,
            fanDoc: fan,
          });
        }
      });
  };

  return { ...userInfoForm, handleSave };
};
